<template>
  <el-drawer title="打印" :visible="visible" direction="rtl"
             :before-close="handleClose" size="37%">
    <iframe class="print-hidden" name="printIframe" id="printIframe" />
    <A4paper class="print-hidden" ref="A4paper" :userInfo="userInfo" :content="content" :checkinRoomDTOList="checkinRoomDTOList"
             :consumptionDTOList="consumptionDTOList" :collectionRefundDTOList="collectionRefundDTOList" :checked="checked"/>
    <Receipt class="print-hidden" ref="Receipt" :userInfo="userInfo" :content="content" :checkinRoomDTOList="checkinRoomDTOList"
             :consumptionDTOList="consumptionDTOList" :collectionRefundDTOList="collectionRefundDTOList" :checked="checked"/>
    <div class="print-wrap">
      <label class="radio">
        <span><i>*</i>打印模式</span>
        <el-radio v-model="model" label="A4paper">A4纸</el-radio>
        <el-radio v-model="model" label="Receipt">80mm热敏打印</el-radio>
      </label>
      <div class="content">
        <h6>打印内容</h6>
        <p>房间信息</p>
        <el-table :data="checkinRoomDTOList" border>
          <el-table-column label="房号" prop="roomNo" width="100" align="center"></el-table-column>
          <el-table-column label="房型" prop="roomTypeName" align="center"></el-table-column>
          <el-table-column label="入理时间" prop="startTime" align="center"></el-table-column>
          <el-table-column label="房费" prop="roomRate" align="center"></el-table-column>
        </el-table>
        <p>其他消费</p>
        <el-table :data="consumptionDTOList" border>
          <el-table-column label="项目" prop="consumptionItemName" width="130" align="center"></el-table-column>
          <el-table-column label="单价" prop="amount" width="100" align="center"></el-table-column>
          <el-table-column label="数量" prop="quantity" width="100" align="center"></el-table-column>
          <el-table-column label="消费时间" prop="updateTime" align="center"></el-table-column>
          <el-table-column label="金额" prop="amount" width="100" align="center"></el-table-column>
        </el-table>
        <p>收款消息</p>
        <el-table :data="collectionRefundDTOList" border>
          <el-table-column label="项目" align="center" width="100">
            <template slot-scope="scope">
              {{ scope.row.itemType | filterItemType(that) }}
            </template>
          </el-table-column>
          <el-table-column label="支付方式" align="center">
            <template slot-scope="scope">
              {{ scope.row.paymentMethod | filterPayMethod(that) }}
            </template>
          </el-table-column>
          <el-table-column label="支付时间" prop="payTime" align="center"></el-table-column>
          <el-table-column label="金额" prop="amount" width="100" align="center"></el-table-column>
        </el-table>
        <el-checkbox v-model="checked">打印备注</el-checkbox>
      </div>
    </div>
    <!-- 打印按钮 -->
    <div class="footer">
      <el-button @click="handleClose">取消</el-button>
      <el-button type="primary" @click="handlePrint">确定</el-button>
    </div>
  </el-drawer>
</template>
<script>
import { mapState } from 'vuex';
import { getContent } from '@/api/pms/accommodationSetting/printSet';
import getDict from "@/utils/dataDict";
import A4paper from './A4paper.vue'
import Receipt from "./Receipt";
import { A4paperCss } from './A4paper.js'
export default {
  name: 'printSheet',
  props: {
    visible: Boolean
  },
  data () {
    return {
      that: this,
      model: 'Receipt',
      userInfo: {},
      checked: true,
      content: '',
      checkinRoomDTOList: [],
      consumptionDTOList: [],
      collectionRefundDTOList: []
    }
  },
  components: {
    A4paper,
    Receipt
  },
  computed: {
    ...mapState(['dictData', 'hotelInfo'])
  },
  mounted() {
    this.getPrintSetCont()
  },
  methods: {
    // 关闭抽屉
    handleClose() {
      this.$emit('close', false)
    },
    // 获取打印内容
    getPrintContent({ checkinRoomDTOList, consumptionDTOList, collectionRefundDTOList, userInfo }) {
      this.userInfo = userInfo
      this.checkinRoomDTOList = checkinRoomDTOList
      this.consumptionDTOList = consumptionDTOList
      this.collectionRefundDTOList = collectionRefundDTOList
    },
    // 获取打印设置
    getPrintSetCont() {
      const params = {
        hotelId: this.hotelInfo.id,
        companyId: this.hotelInfo.storeId
      }
      getContent(params).then(({ success, records }) => {
        if (!success) return
        if (records.length > 0) this.content = records[0].content
      })
    },
    // 处理打印
    handlePrint() {
      const iframe = window.frames['printIframe']
      iframe.document.body.innerHTML = this.$refs[this.model].$el.innerHTML
      iframe.document.head.innerHTML = A4paperCss
      iframe.print()
    }
  },
  watch:{
    visible(newVal){
      newVal && getDict('pms-pay-method')
    }
  },
  filters: {
    filterItemType(val, that) {
      let arr = [...that.dictData['back-money-type-list'] , ...that.dictData['income-list'] ];
      if (val && arr.length){
        arr.forEach(item=>{
            if(item.value === val) val = item.label
        })
        return val
      }
    },
    filterPayMethod(val, that) {
      if (val && that.dictData['pms-pay-method']) return that.dictData['pms-pay-method'][val] || val
    }
  }
}
</script>
<style scoped lang="scss">
.print-wrap{
  padding: 30px;
  .radio{
    span {
      i { color: #F56C6C; margin-right: 5px }
      font-size: 14px; margin-right: 10px
    }
  }
  .content {
    margin: 30px 0 0 0;
    h6 { font-size: 18px }
    p { font-size: 14px; margin: 30px 0 10px 0 }
  }
}
.print-hidden {
  visibility: hidden; position: absolute;
}
.footer {
  position: fixed; right: 20px; bottom: 20px;
}
</style>
