<template>
  <div id="print-order-main">
    <div>
      <div>
        <div class="flex-between-center">
          <div class="ntw-name">{{ hotelInfo.hotelName }}</div>
          <div><span class="mr-16">打印时间：{{ currentTime }}</span><span>操作人：{{ accountName }}</span></div>
        </div>
        <div class="title">住宿登记单</div>
        <div class="user-info border-bottom">
          <div class="flex-between-center w-full"><span class="flex-1 w-full">姓名：{{ userInfo.realName }}</span>
            <span class="flex-1 w-full">电话：{{ userInfo.mobile }}</span>
            <!----></div><!----></div>
      </div>
      <div class="form-title">房间信息</div>
      <table class="form-content">
        <thead>
        <tr>
          <th class="w-110">房号</th>
          <th style="width: 160px;">房型</th>
          <th class="nowrap">入离日期</th>
          <th class="nowrap">房费（元）</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, key) in checkinRoomDTOList" :key="key">
          <td>{{ item.roomNo }}</td>
          <td>{{ item.roomTypeName }}</td>
          <td class="nowrap">{{ item.startTime }} 至 {{ item.endTime }}</td>
          <td class="nowrap">{{ item.roomRate }}</td>
        </tr>
        </tbody>
      </table>
      <div class="form-title">其他消费</div>
      <table class="form-content">
        <thead>
        <tr>
          <th class="w-110">项目</th>
          <th style="width: 160px;">单价</th>
          <th class="nowrap">数量</th>
          <th class="nowrap">消费时间</th>
          <th class="nowrap">金额</th>
        </tr>
        </thead>
        <tbody v-if="consumptionDTOList.length > 0">
          <tr v-for="(item, key) in consumptionDTOList" :key="key">
            <td>{{ item.consumptionItemName }}</td>
            <td>{{ item.amount }}</td>
            <td class="nowrap">{{ item.quantity }}</td>
            <td class="nowrap">{{ item.updateTime }}</td>
            <td class="nowrap">{{ item.amount }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="5" style="text-align: center">暂无数据</td>
          </tr>
        </tbody>
      </table>
      <div class="form-title" v-if="collectionRefundDTOList.length > 0">收款消息</div>
      <table class="form-content" v-if="collectionRefundDTOList.length > 0">
        <thead>
          <tr>
            <th class="w-110">项目</th>
            <th style="width: 160px;">支付方式</th>
            <th class="nowrap">支付时间</th>
            <th class="nowrap">金额</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in collectionRefundDTOList" :key="key">
            <td>{{ item.itemType | filterItemType(that) }}</td>
            <td>{{ item.paymentMethod | filterPayMethod(that) }}</td>
            <td class="nowrap">{{ item.payTime }}</td>
            <td class="nowrap">{{ item.amount }}</td>
          </tr>
        </tbody>
      </table>
      <div class="border-bottom pb-24"></div>
      <div v-if="checked" style="margin-top: 20px; font-size: 12px">
        <p style="margin-bottom: 10px">订单备注#</p>
        <p>{{ userInfo.remark || '无' }}</p>
      </div>
      <div class="state pt-24">
        <div>{{ content }}</div>
        <div class="sign"><span>员工签字</span><span class="line"></span><span>客人签字</span><span class="line"></span></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { dateFactory } from '@/utils/dateFactory';
export default {
  name: 'A4paper',
  props: {
    userInfo: {
      type: Object,
      default: () => {}
    },
    checkinRoomDTOList: {
      type: Array,
      default: () => []
    },
    consumptionDTOList: {
      type: Array,
      default: () => []
    },
    collectionRefundDTOList: {
      type: Array,
      default: () => []
    },
    content: String,
    checked: Boolean
  },
  data () {
    return {
      that: this,
      currentTime: '',
      accountName: ''
    }
  },
  mounted() {
    this.accountName = JSON.parse(sessionStorage.getItem('userInfo') || '{}').userName
    this.currentTime = dateFactory.dateFormat(true)
  },
  computed: {
    ...mapState(['dictData','hotelInfo'])
  },
  filters: {
    filterItemType(val, that) {
      let arr = [...that.dictData['back-money-type-list'] , ...that.dictData['income-list'] ];
      if (val && arr.length){
        arr.forEach(item=>{
            if(item.value === val) val = item.label
        })
        return val
      }
    },
    filterPayMethod(val, that) {
      if (val && that.dictData['pms-pay-method']) return that.dictData['pms-pay-method'][val] || val
    }
  }
}
</script>
