<template>
   <div>
      <!-- 当为修改订单时，显示遮罩层 -->
      <div class="addAtrialstate_mask" v-if="drawerBools && bookId !== '' && bookId !== 'add'" ></div>
      <div class="addAtrialstate"  :style="{right:`${ drawerBools ? '0' : '-36%' }`}">
         <!-- header -->
         <div class="drawer-header border-b">
            <el-tabs v-if="bookIds === 'add'" v-model="activeName" @tab-click="changeTabs"><el-tab-pane v-for="item in timeroonList" :key="item.value" :label="item.label" :name="item.value"></el-tab-pane></el-tabs>
            <el-page-header v-if="bookIds !== 'add'"  @back="closeDrawer" content="修改订单"></el-page-header>
            <span class="cursor-p m-right-10" @click="closeDrawer">X</span>
         </div>

         <!-- main -->
         <div class="drawer-main">
            <el-scrollbar class="atrial-scrollbar" style="height: 100%">
               <div style="padding: 0 20px;">
                  <!-- 基本信息 -->
                  <div class="m-top-20 border-b basicInfo">
                     <h4 class="m-bottom-20">基本信息</h4>
                     <el-form ref="ruleForm" :model="ruleForm" :rules="rules" class="form-basicInfo" label-position="right" label-width="51px" v-if="drawerBools">
                        <el-form-item label="姓名">
                           <el-input class="width-190" v-model="ruleForm.realName" placeholder="请输入姓名" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="手机" prop="mobile">
                           <el-input class="width-190" v-model="ruleForm.mobile" placeholder="请输入手机号码" @change="changeInput" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="渠道" prop="channel">
                           <el-select class="width-190" v-model="ruleForm.channel" placeholder="请选择渠道" @change="channelchange">
                              <el-option
                                    v-for="item in dictData['order-source-list']"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                              </el-option>
                           </el-select>
                           <el-button type="primary" plain size="small" icon="el-icon-search" class="m-left-10" @click="dialogMemberSearch = true;Keyword='';selectShow = false">搜索</el-button>
                           <span style="color: #606266;" v-if="ruleForm.channel==='AGREEMENT'&&Keyword&&selectShow">已选择：{{ Keyword }}</span>
                        </el-form-item>
                        <el-row v-if="LevelBool" style="line-height: 40px;">
                           <!-- 协议用户   会员权益levelId是unitId协议单位，出现下拉框 -->
                           <span class="m-right-10" v-if="LevelAndUnit.levelId && LevelAndUnit.unitId && LevelAndUnit.levelName">
                              <el-select class="width-120" v-model="memberPriceType" placeholder="请选择" >
                                 <!-- AGREEMENT_UNIT-协议 MEMBER_LEVEL-会员 -->
                                 <el-option
                                       v-for="item in [{label:'协议用户',value:'AGREEMENT_UNIT'},{label:this.LevelAndUnit.levelName,value:'MEMBER_LEVEL'}]"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.value">
                                 </el-option>
                              </el-select>
                           </span>

                           <!-- 会员权益 -->
                           <span v-else-if="LevelAndUnit.levelName" style="color: #ff0000;margin-right: 10px">{{LevelAndUnit.levelName}}</span>
                           <!-- 协议用户 -->
                           <span v-else-if="memberPriceType === 'AGREEMENT_UNIT'" style="color: #ff0000;margin-right: 10px">协议用户</span>

                           <span class="m-right-10" v-if="(memberPriceType === 'MEMBER_LEVEL' && LevelAndUnit.levelId && LevelAndUnit.discount)
                           || (memberPriceType === 'AGREEMENT_UNIT' && LevelAndUnit.unitId)" >
                              <el-button type="primary" size="medium" @click="changePrice">一键改价</el-button>
                           </span>
                        </el-row>
                     </el-form>
                  </div>
                  <!-- 房间信息 -->
                  <div class="m-top-20 border-b combinInfo roomsInfo">
                     <el-row class="m-bottom-20">
                        <div class="float-l"><h4>房间信息</h4></div>
                        <div class="float-r"> 共 {{ activeName === 'FULL_TIME' ? roomsList.length : hourRoomsList.length }} 间房 </div>
                     </el-row>

                     <!-- 全日房 -->
                    <div v-if="activeName === 'FULL_TIME'">
                       <div v-for="(item,index) in roomsList" :key="index" class="m-bottom-10">
                          <el-input class="input-combin" placeholder="价格" v-model="item.roomRate" style="width: 515px" >
                             <!-- 全日房 -->
                             <el-date-picker class="width-135 datePicker" slot="prepend" value-format="yyyy-MM-dd" :clearable="false" :editable="false"
                                             v-model="item.startTime" type="date" placeholder="选择日期" @change="changeCombin($event,index,item)"></el-date-picker>
                             <el-input-number class="width-135" slot="prepend" v-model="item.duration" label="描述文字"
                                              @change="(currentValue, oldValue)=>{changeDuration(currentValue, oldValue,item,index)}" :min="1" :max="item.max" ></el-input-number>
                             <el-cascader class="width-137" slot="prepend" v-model="item.rooms" :options="roomOptions" style="line-height: 37px"
                                          @change="changeCombin($event,index,item)" @visible-change="(bool)=>{ visibleChange(bool,item) }"></el-cascader>
                             <span slot="prefix" class="RMB">￥</span>
                          </el-input>
                          <el-badge :value="item.checkinMemberList ? item.checkinMemberList.length : 0" class="badge-checkin" type="primary" @click.native="checkinAdd(item)">入住人</el-badge>
                          <!-- <el-button @click="handleBusinessCard(item)" type="text">制卡</el-button> -->
                          <el-button class="hover-delete" type="text" icon="el-icon-delete" :disabled="bookIds !== 'add' && item.disabledDel ? true : false"  @click="roomsDel(item,index)"></el-button>
                       </div>
                    </div>

                     <!-- 钟点房 -->
                    <div v-if="activeName === 'HOUR_ROOM'">
                       <div v-for="(item,index) in hourRoomsList" :key="index" class="m-bottom-10">
                          <el-input class="input-combin" placeholder="价格" v-model="item.roomRate" style="width: 515px" >
                             <!-- 钟点房 -->
                             <el-date-picker class="width-135 datePicker" slot="prepend" value-format="yyyy-MM-dd HH:mm:ss" :clearable="false" :picker-options="disable_pickerOptions()"
                                             v-model="item.startTime" type="datetime" placeholder="选择时间" @change="changeCombin($event,index,item)"></el-date-picker>

                             <el-input-number class="width-135" slot="prepend" v-model="item.duration" label="描述文字"
                                              @change="(currentValue, oldValue)=>{changeDuration(currentValue, oldValue,item,index)}" :min="1" :max="item.max" ></el-input-number>
                             <el-cascader class="width-137" slot="prepend" v-model="item.rooms" :options="roomOptions" style="line-height: 37px"
                                          @change="changeCombin($event,index,item)" @visible-change="(bool)=>{ visibleChange(bool,item) }"></el-cascader>
                             <span slot="prefix" class="RMB">￥</span>
                          </el-input>
                          <el-badge :value="item.checkinMemberList ? item.checkinMemberList.length : 0" class="badge-checkin" type="primary" @click.native="checkinAdd(item)">入住人</el-badge>
                          <!-- <el-button @click="handleBusinessCard(item)" type="text">制卡</el-button> -->
                          <el-button class="hover-delete" type="text" icon="el-icon-delete" :disabled="bookIds !== 'add' && item.disabledDel ? true : false"  @click="roomsDel(item,index)"></el-button>

                          <div v-if="activeName !== 'FULL_TIME' && item.startTime" style="font-size: 13px;color: #99a9bf;margin-top:5px;">
                             {{item.startTime}} <span v-html="'&nbsp;至&nbsp;'"></span>{{getAfterHour(item.startTime,item.duration)}}
                          </div>
                       </div>
                    </div>

                     <el-button class="m-bottom-20" type="primary" plain size="small" icon="el-icon-plus" @click="roomsAdd">添加房间</el-button>
                     <el-button class="m-bottom-20" type="primary" plain size="small" icon="el-icon-plus" @click="consumeAdd(true)" v-if="!consumptionDTOList.length">添加消费</el-button>
                  </div>

                  <!-- 添加消费 -->
                  <div class="m-top-20 border-b combinInfo consumeInfo" v-if="consumptionDTOList.length">
                     <h4 class="m-bottom-20">消费信息</h4>
                     <div v-for="(item,index) in consumptionDTOList" :key="index" class="m-bottom-10">
                        <el-input class="input-combin" placeholder="金额" v-model="item.amount" style="width: 540px">
                           <!-- <el-select class="width-137" slot="prepend" v-model="item.consumptionItemName" placeholder="项目"
                                      @change="(value)=>{consumptionProject(value,item,index)}" style="margin:0 0 0 1px!important;" >
                              <el-option
                                    v-for="items in consumeOptions"
                                    :key="items.id"
                                    :label="items.name"
                                    :value="items.id">
                              </el-option>
                           </el-select> -->
                           <el-cascader :options="consumeOptions" :show-all-levels="false" class="width-137" slot="prepend" placeholder="项目"
                            v-model="item.consumptionItemName" @change="(value)=>{consumptionProject(value,item,index)}"
                            ></el-cascader>
                           <el-input-number class="width-135" slot="prepend" v-model="item.quantity" :min="1" :max="10" label="晚"
                                            @change="(currentValue)=>{consumptionQuantity(currentValue,item)}"></el-input-number>
                           <el-date-picker class="width-137" slot="prepend" value-format="yyyy-MM-dd HH:mm:ss"  v-model="item.consumptionTime" type="date" placeholder="消费日期"></el-date-picker>
                           <span slot="prefix" class="RMB">￥</span>
                        </el-input>
                        <el-popover placement="top-start" width="300" trigger="click">
                           <el-input type="textarea"  v-model="item.remark" :rows="3" maxlength="200" show-word-limit placeholder="备注信息">
                           </el-input>
                           <el-badge :is-dot="item.remark ? true : false" class="badge-checkin" type="primary" slot="reference">备注</el-badge>
                        </el-popover>
                        <el-button class="hover-delete" type="text" icon="el-icon-delete" @click="consumeDel(item,index)"></el-button>
                     </div>
                     <el-button class="m-bottom-20" type="primary" plain size="small" icon="el-icon-plus" @click="consumeAdd(false)">添加消费</el-button>
                  </div>
                  <!-- 收款信息 -->
                  <div class="m-top-20 border-b combinInfo collection" v-if="bookIds === 'add'">
                     <h4 class="m-bottom-20">收款信息</h4>
                     <div v-for="(item,index) in collectionList" :key="index" class="m-bottom-10">
                        <el-input class="input-combin" placeholder="金额" v-model="item.amount" style="width: 540px">
                           <el-select class="width-137" slot="prepend" v-model="item.itemType"  placeholder="项目" style=" margin:0 0 0 1px!important;">
                              <el-option v-for="items in dictData['income-list']" :key="items.value" :label="items.label" :value="items.value"></el-option>
                           </el-select>
                           <el-select class="width-137" slot="prepend" v-model="item.paymentMethod"  placeholder="支付方式" style=" margin:0 0 0 1px!important;">
                              <el-option v-for="items in modePaymentList" :key="items.id" :label="items.name" :value="items.name"></el-option>
                           </el-select>
                           <el-date-picker class="width-137 datePicker" slot="prepend" v-model="item.payTime"  value-format="yyyy-MM-dd" type="date" placeholder="收款日期"></el-date-picker>
                           <span slot="prefix" class="RMB">￥</span>
                        </el-input>
                        <el-popover placement="top-start" width="300" trigger="click">
                           <el-input type="textarea"  v-model="item.remark" :rows="3" maxlength="200" show-word-limit placeholder="备注信息">
                           </el-input>
                           <el-badge :is-dot="item.remark ? true : false" class="badge-checkin" type="primary" slot="reference">备注</el-badge>
                        </el-popover>
                        <el-button class="hover-delete" type="text" icon="el-icon-delete" @click="collectionDel(item,index)"></el-button>
                     </div>
                     <el-button class="m-bottom-20" type="primary" plain size="small" icon="el-icon-plus" @click="collectionAdd">添加收款</el-button>
                  </div>
                  <!-- 提醒信息 -->
                  <div class="m-top-20 border-b warnInfo">
                     <h4 class="m-bottom-20">提醒信息</h4>
                     <div class="m-bottom-10" v-for="(item,index) in orderReminderList" :key="index" >
                        <div class="warn-item">
                           <span class="m-left-10">{{ item.reminderTime}}</span>
                           <span class="m-left-10">{{ item.reminderContent}}</span>
                        </div>
                        <div style="display: inline-block;vertical-align: top">
                           <el-button class="m-left-10" type="text" icon="el-icon-edit" @click="warnEdit(item,index)"></el-button>
                           <el-button class="hover-delete " type="text" icon="el-icon-delete" @click="warnDel(item,index)"></el-button>
                        </div>
                     </div>
                     <el-button class="m-bottom-20" type="primary" plain size="small" icon="el-icon-plus" @click="warnAdd">添加提醒</el-button>
                  </div>
                  <!-- 备注 -->
                  <div  class="m-top-20" style="height: 145px;">
                     <el-input type="textarea"  v-model="remark" maxlength="2048"  rows="5" show-word-limit clearable  placeholder="备注信息"></el-input>
                  </div>
               </div>
            </el-scrollbar>
         </div>
         <!-- footer -->
         <div class="drawer-footer" style="display: flex;justify-content: space-between;">
            <div :style="{lineHeight:`${ bookIds === 'add' ? '65px' : '25px' }`,marginTop:'6px'}">
               <div>订单金额: <span style="color: #0c7ffd">￥{{amountsPayable}}</span> </div>
               <div v-if="bookIds !== 'add'" style="font-size: 12px">
                  已付：<span style="color: coral;margin-right: 5px;">￥{{ actualAmount }}</span>
                  还需付款：<span style="color: red;">￥{{debtAmount}}</span>
               </div>
            </div>
            <div>
               <el-button v-if="checkInState === 'CHECKIN'"  @click="handleSave('ruleForm','入住')">直接入住</el-button>
               <el-button class="bg-gradient" type="primary" style="margin:12px 20px;" @click="handleSave('ruleForm',btnText)">{{btnText}}</el-button>
            </div>
         </div>
      </div>
     <!-- 快速制卡 抽屉 -->
     <business-card ref="businessCard" :visible="visibleCard" @close="closeCardDrawer"/>
      <!-- 入住人 抽屉 -->
      <drawer-checkin :checkInpersonBool="checkInpersonBool" :currentCheck="currentCheckinRoom" :checkinList="checkinList"
                      :state="bookIds === 'add' ? 'edit' : 'EditInfo'" @closeCheckin="checkinClose" @handleFinish="handleFinish"></drawer-checkin>
      <!-- 提醒信息 抽屉 -->
      <Drawer :bool="warnBool" :headerTitle="`${ warnState === 'add' ? '添加' : '编辑' }提醒`" @close="warnClose">
         <template v-slot:drawerMain>
            <el-form ref="warnRuleForm" :model="warnRuleForm" :rules="warnRules" label-position="right" label-width="100px" style="margin: 20px 20px 0 20px;">
               <el-form-item label="提醒时间" prop="reminderTime" >
                  <el-date-picker v-model="warnRuleForm.reminderTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="disable_pickerOptions(warnRuleForm.reminderTime)" placeholder="选择日期时间"></el-date-picker>
               </el-form-item>
               <el-form-item label="提醒内容" prop="reminderContent">
                  <el-input type="textarea" class="width-300" v-model="warnRuleForm.reminderContent" maxlength="200"  rows="4" show-word-limit clearable  placeholder="请输入提醒内容"></el-input>
               </el-form-item>
            </el-form>
            <!-- footer -->
            <div class="drawer-footer t-right">
               <el-button @click="warnClose(false)">取消</el-button>
               <el-button class="bg-gradient" type="primary" style="margin:0px 20px" @click="warnFinish('warnRuleForm')">完成</el-button>
            </div>
         </template>
      </Drawer>
      <!-- 协议搜索\会员搜索 -->
      <el-dialog :title="ruleForm.channel==='AGREEMENT'?'协议搜索':'会员搜索'" :visible.sync="dialogMemberSearch" width="30%">
         <el-autocomplete class="inline-input" :placeholder="ruleForm.channel==='AGREEMENT'?'输入单位名称':'输入手机号/微信号/姓名'" :trigger-on-focus="false" value-key="value"
            v-model="Keyword" :fetch-suggestions="querySearch" suffix-icon="el-icon-search" style="width: 300px;margin-bottom: 30px;margin-left: 115px;"></el-autocomplete>
         <span slot="footer" class="dialog-footer">
            <el-button @click="dialogMemberSearch = false">取 消</el-button>
            <el-button type="primary" @click="confirmSearch">确 定</el-button>
         </span>
      </el-dialog>
   </div>
</template>

<script>
import { mapState } from "vuex";
import Drawer from "@/components/global/drawer";
import BusinessCard from "@/components/local/atrialState/BusinessCard";
import { getEnabledList } from '@/api/pms/collectionSetting/collectionSetting'
import { getListConsumptionItem } from "@/api/pms/accommodationSetting/consumerItemSet";
import { search_substr_pos, getDict, getDuplicate, dateFactory, verificationRule, accuracyCalculation } from '@/common/js/common';
import { insertBookInfo, getReserveDetail, getRoomPriceList, getLevelAndUnit, getMemberByKeyword, getAgreementUnitByName} from '@/api/pms/roomCalendar'
export default {
   name: "AddAtrialstate",
   components: {
      Drawer,
      BusinessCard
   },
   props:{
      // 是否显示
      drawerBool:{
         type:Boolean,
         default:false,
      },
      bookId:{
          type:String,
          default:'add'
      },
      checkInState:{
        type:String,
        default:'NOT_CHECKIN'
      },
      // 房间信息数据
      currentData:{
         type:Array,
         default: function (){
            return []
         },
      },
   },
   watch:{
      async drawerBool(newVal){
         newVal && await getDict(["order-source",'payment-method','income',],false)
         this.drawerBools = this.drawerBool
         if(this.drawerBool) {
            this.activeName = 'FULL_TIME'                      // 全日房默认值
            if(this.dictData['order-source-list'].length) this.ruleForm.channel = this.dictData['order-source-list'][0].value // 渠道默认值
            // 编辑
            if(this.bookId !== '' && this.bookId !== 'add'){
               this.$emit('changeNotAllowed',true)  // 祖组件 禁止操作
               this.getDetail()
            }else{
               this.getallRoomPriceList(["price"])     // 获取价格和房态
            }
            this.getPayList()
         }
      },
      bookId(){ this.bookIds = this.bookId },
      currentData(){ this.roomsList = this.currentData },
      checkInState(){ this.checkInStates = this.checkInState }
   },
   data(){
      let validTelephone = (rule, value, callback) => {
         if (value === '') {
            callback(new Error('请输入手机号'))
         }else if (verificationRule.mobile(value)){
            callback(new Error('手机号格式错误'))
         } else {
            callback()
         }
      }
      return{
         hotelId:'',
         companyId:'',                 // 集团id
         mapT:new Map(),
         visibleCard: false,           // 制卡抽屉是否显示

         /** 客户搜索 */
         dialogMemberSearch:false,     // 会员搜索是否显示
         Keyword:'',                   // 关键字
         selectShow:false,             // 协议单位公司 选中提示

         drawerBools:false,            // 是否显示
         bookIds:'add',                // 添加/编辑预订单
         checkInStates:'NOT_CHECKIN',   // 添加预订单还是直接入住
         removeMemberIds:[],           // 编辑预订单-删除入住人数据id集合

         timeroonList:[
            {value:'FULL_TIME',label:'全日房'},
            {value:'HOUR_ROOM',label:'钟点房'}
         ],
         activeName:'FULL_TIME',    // 全日房

         // 基本表单信息
         ruleForm:{
            realName:'',
            mobile:'',
            channel:'',
         },
         rules: {
            realName: [{ required: true, trigger: "blur", message: "请输入姓名" }],
            mobile: [ {required: true, trigger: 'blur' , validator: validTelephone}],
            channel: [{ required: true, trigger: "change", message: "请选择渠道" }],
         },

         LevelBool:false,           //
         LevelAndUnit:{},           //
         memberPriceType:'AGREEMENT_UNIT',   // 协议用户/会员折扣

         // 房间信息
         roomsList:[],              // 全日房间信息
         hourRoomsList:[],          // 钟点房间信息
         roomOptions: [],           // 房型房号 options

         // 入住人抽屉
         checkinList:[{
            realName:'',
            mobile:'',
            certificateType:'',     // 证件类型
            certificateNo:'',       // 证件号
         }],
         checkInpersonBool:false,   // 是否显示入住人 抽屉
         currentCheckinRoom:{},     // 当前入住人房间信息

         // 消费信息
         consumptionDTOList:[],
         consumeOptions:[],         // 消费项目 options
         removeConsIds:[],          // 编辑时-移除的消费信息id集合

         // 收款信息
         collectionList:[],
         // 支付方式列表
         modePaymentList:[],

         // 提醒信息
         orderReminderList:[],      // 提醒数据
         warnBool:false,            // 是否打开抽屉
         warnState:'add',           // 新增/编辑 提醒
         warnIndex:null,            // 编辑下标
         warnRuleForm:{
            reminderTime:'',        // 提醒时间
            reminderContent:''      // 提醒内容
         },
         warnRules:{
            reminderTime: [{ required: true, trigger: "change", message: "请选择日期" }],
            reminderContent: [{ required: true, trigger: "blur", message: "请输入提醒内容" }],
         },

         // 备注
         remark:'',
         actualAmount:0,            // 实付金额（订单收款）
         debtAmount:0,              // 还需付款
      }
   },
   computed:{
      ...mapState(['dictData','hotelInfo']),
      // 计算 应付金额
      amountsPayable(){
         let checkInType = 'hourRoomsList'
         if(this.activeName === 'FULL_TIME') checkInType = "roomsList"
         let amountsPayable = 0 // 应付金额（订单总价-优惠金额）未计算减去优惠金额
         // 房间信息总价格
         this[checkInType].forEach(item=>{
            if(!isNaN(Number(item.roomRate))) {
               amountsPayable = accuracyCalculation(Number(item.roomRate),amountsPayable,'+')
            }
         });
         // 附上 消费信息总价格
         this.consumptionDTOList.forEach(item=>{ amountsPayable = accuracyCalculation(amountsPayable,Number(item.amount),'+') })
         // 附上 收款信息
         // this.collectionList.forEach(item=>{ amountsPayable = amountsPayable - item.price })
         return amountsPayable
      },

      btnText(){
         if(this.bookIds === 'add'){
            let now = dateFactory.timestampFormat(new Date().getTime()/1000,false),
            text = '新增订单';
            this.roomsList.forEach( item => {
               if( dateFactory.compareTime(now, item.startTime) ){ text = '补录订单' }
            })
            return text
         }else{
            return '保存'
         }
      }
   },
   mounted() {
      this.drawerBools = this.drawerBool
      this.bookIds = this.bookId
      this.roomsList = this.currentData
      this.hotelId =  this.hotelInfo.id
      getDict(['pms-pay-method'],false)
   },
   methods:{
      // 获取 支付方式列表
      getPayList(){
         getEnabledList({ hotelId: this.hotelInfo.id }).then(({ records }) => {
            this.modePaymentList = records
         })
      },
      // 查询预订单详情 处理回显
     getDetail(){
         getReserveDetail(this.bookIds).then(res=>{
            if(res.success){
               let resData = res.records
               // 基本信息 回显
               for (const k in this.ruleForm) k in resData && (this.ruleForm[k] = resData[k])
               resData.checkinRoomDTOList.forEach((item,index)=>{
                  item.disabledDel = true                       // 原订单中的数据不可整条删除
                  if(!index) this.activeName = item.checkInType
                  if(item.checkInType === 'FULL_TIME') item.duration = dateFactory.getDistanceDays(item.startTime.substring(0,10),item.endTime.substring(0,10),false)
                  else item.duration = dateFactory.getDistanceDays(item.startTime,item.endTime,true)/3600000
                  if(!item.hasOwnProperty('roomId')) item.roomId = '',item.roomRate = 0
                  item.rooms = [item.roomTypeId,item.roomId]    // [房型id,房间号id]
               })
               // 房间信息 回显
               if(this.activeName === 'FULL_TIME') this.roomsList = resData.checkinRoomDTOList
               else this.hourRoomsList = resData.checkinRoomDTOList

               // 消费信息 回显
               this.consumptionDTOList = resData.consumptionDTOList
               // 收款信息 回显
               this.collectionList = resData.collectionRefundDTOList

               // 提醒信息 回显
               this.orderReminderList = resData.orderReminderList
               // 备注 回显
               this.remark = resData.remark
               // 已付金额
               this.actualAmount = resData.actualAmount
               // 还需付款
               this.debtAmount =  accuracyCalculation(Number(resData.amountsPayable),this.actualAmount,'-')  // 解决精度丢失问题

               this.getCascader(['onlyCascader'])
            }
         })
      },
      // 关闭抽屉
      closeDrawer(){
         // 清空数据
         for (const k in this.ruleForm) this.ruleForm[k] = ''
         this.roomsList = []
         this.hourRoomsList = []
         this.consumptionDTOList = []
         this.collectionList = []
         this.orderReminderList = []
         this.remark = ''
         this.drawerBools = false
         this.LevelBool = false
         this.selectShow = false
         // 当为编辑时，调用祖组件中的changeNotAllowed事件，更新
         if(this.bookIds !== 'add') this.$emit('changeNotAllowed',false) //  调用祖组件 更新禁用状态
         this.$emit("closeDrawer",this.drawerBools)
      },
      // 提交
      handleSave(formName,text){
         setTimeout(() => {
            this.handleSave2(formName,text)
         }, 200);
      },
      handleSave2(formName,text){
         this.$refs[formName].validate((valid) => {
            if (valid) {
               let errorMessage = false

               let checkinRoomDTOList = []
               let orderTotalPrice = 0  // 订单总价

               let checkInType = 'hourRoomsList'                             // 钟点房
               if(this.activeName === 'FULL_TIME') checkInType = 'roomsList' // 全日房

               // 表单验证
               if(this[checkInType].length === 0) return this.$message.warning("请至少添加一条房间信息")

               // 房间信息
               this[checkInType].forEach(item=>{
                  if(!isNaN(Number(item.roomRate))) orderTotalPrice = Number(item.roomRate) + orderTotalPrice

                  let startTime = ''  // 开始时间
                  let endTime = ''    // 结束时间
                  // 全日房
                  if(this.activeName === 'FULL_TIME'){
                     startTime = item.startTime.indexOf(":") === -1 ? item.startTime +' 14:00:00' : item.startTime
                     if(item.endTime) endTime = item.endTime.indexOf(":") === -1 ? item.endTime + ' 13:59:59' : item.endTime
                     else endTime = item.startTime.indexOf(":") === -1 ? item.startTime +' 13:59:59' : item.startTime.substring(0,10) +' 13:59:59'
                  }else{ // 钟点房
                     startTime = item.startTime
                     endTime = this.getAfterHour(item.startTime,item.duration)
                  }

                  // 新增 
                  if(this.bookIds === 'add'){
                     if(item.hasOwnProperty('checkinMemberList')) item.checkinMemberList.forEach(items=>{ items.hotelId = this.hotelId })
                     let pushItem = {
                        hotelId:this.hotelId,
                        roomId:item.rooms[1],                                    // 房间id
                        roomTypeId:item.rooms[0],                                // 房间类型
                        roomNo:this.mapT.get(item.rooms[1]),                     // 房号
                        roomTypeName:this.mapT.get(item.rooms[0]),
                        startTime: startTime,                                    // 开始入住时间
                        endTime:endTime,                                         // 离店时间
                        checkInType: this.activeName,                            // 入住类型  HOUR_ROOM 钟点房;FULL_TIME 全日房
                        roomRate:item.roomRate,                                  //  房费
                        duration:item.duration,                                  // 入住天数/小时
                        checkinMemberList: item.hasOwnProperty('checkinMemberList') ? item.checkinMemberList : [] ,// 入住会员信息
                        checkInState:this.checkInStates,                         //NOT_CHECKIN-预定 CHECKIN-入住
                     }

                     // 未排房
                     if(pushItem.roomNo === 'undefined'){
                        delete pushItem.roomNo
                        delete pushItem.roomId
                     }
                     checkinRoomDTOList.push(pushItem)
                  } else {
                     // 编辑
                     if(item.hasOwnProperty('checkinMemberList')){
                        item.checkinMemberList.forEach(items=>{
                           delete items.checkinRoomId
                           delete items.hotelId
                           delete items.isDelete
                        })
                     }
                     // 房间信息
                     checkinRoomDTOList.push({
                        id:item.id ,
                        roomId:item.rooms[1],
                        roomTypeId:item.rooms[0],
                        roomNo:this.mapT.get(item.rooms[1]),
                        roomTypeName:this.mapT.get(item.rooms[0]),
                        startTime:startTime,
                        endTime:endTime,
                        checkInType:item.checkInType ? item.checkInType : this.activeName,
                        roomRate:item.roomRate,
                        duration:item.duration,
                        checkinMemberList:item.checkinMemberList ? item.checkinMemberList : [],
                     })
                  }
               })

               // 消费信息
               this.consumptionDTOList.forEach(item=>{
                  orderTotalPrice = orderTotalPrice + Number(item.amount)
                  if(this.bookIds === 'add'){
                     delete item.price
                  }else{
                     delete item.bookId
                     delete item.createOperator
                     delete item.createTime
                     delete item.isDelete
                     delete item.refUserName
                     delete item.updateOperator
                     delete item.updateTime
                  }
                  if(!item.consumptionItemName) errorMessage = '请选择消费项目!'
               })

               // 收款信息
               let actualAmount = 0 // 实付金额
               this.collectionList.forEach(item=>{
                  item.amount = Number(item.amount)
                  item.payTime = item.payTime.indexOf(':') === -1 ? item.payTime + ' 00:00:00' : item.payTime
                  if(this.bookIds === 'add') actualAmount = actualAmount + item.amount
                  if(!item.itemType) errorMessage = '请选择收款项目!'
                  if(!item.paymentMethod) errorMessage = '请选择收款支付方式!'
                  if(!item.amount > 0) errorMessage = '请输入大于0的收款金额!'
               })
               if(this.bookIds !== 'add') actualAmount = this.actualAmount

               // 提醒信息
               this.orderReminderList.forEach(item=>{
                  item.hotelId = this.hotelId;
                  if(this.bookIds !== 'add') {
                     delete item.createOperator;
                     delete item.createTime;
                     delete item.updateTime;
                     delete item.isDelete;
                     delete item.updateOperator;
                     item.bookId = this.bookIds;
                  }
               })
               // 优惠金额
               let discountedPrice = 0 // 优惠金额
               // if(this.bookIds === 'add') discountedPrice 获取此订单可以优惠多少金额
               let amountsPayable = orderTotalPrice - discountedPrice
               // 姓名默认修改为: 散客
               if (this.ruleForm.realName==='') {
                  this.ruleForm.realName = '散客'
               }
               let param = {
                  ...this.ruleForm,
                  remark:this.remark,
                  hotelId:this.hotelId,
                  companyId:this.hotelInfo.storeId,
                  checkoutState:amountsPayable > actualAmount ? "DEBT" : 'SETTLE' ,   // DEBT 欠款  SETTLE 结清  // 应付金额大于实付金额时为欠款，否则为结清

                  amountsPayable:amountsPayable,                   // 应付金额（订单总价-优惠金额）  amountsPayable - actualAmount
                  actualAmount:actualAmount,                       // 实付金额（订单收款）
                  orderTotalPrice:orderTotalPrice,                 // 订单总价
                  discountedPrice:discountedPrice,                 // 优惠金额 -
                  checkinRoomDTOList:checkinRoomDTOList,           // 入住房间信息
                  consumptionDTOList:this.consumptionDTOList,      // 消费信息
                  collectionRefundDTOList:this.collectionList,     // 收款信息 this.collectionList -
                  orderReminderList:this.orderReminderList,        // 提醒信息
               }
               if(this.bookIds !== 'add') {
                  param.id = this.bookIds
                  param.removeConsIds =  this.removeConsIds         // 移除的消费id集合
                  param.removeMemberIds = this.removeMemberIds      // 需要移除的会员Id列表
                  // param.channelOrderNo                           // 不传
                  delete param.discountedPrice                      // 优惠金额
                  delete param.collectionRefundDTOList              // 收款信息
               }

              // 验证
               if(errorMessage) return this.$message.error(errorMessage)

               // 全日房
               if(this.activeName === 'FULL_TIME'){
                  // 修改入住截止时间
                  param.checkinRoomDTOList.forEach(item =>{
                     item.endTime = dateFactory.addDays(item.startTime,item.duration) + ' 13:59:59'
                  })
               }
               let bool = false
               param.checkinRoomDTOList.forEach(el => {
                  if (el.roomTypeId === undefined) {
                     return bool = true
                  }
               });
               if (bool) {
                  return this.$message.warning("请填写完整房间信息")
               }
               insertBookInfo(param,this.bookIds).then( res => {
                  if(res.success){
                     if(this.bookIds === 'add'){
                        this.$message.success(text+'成功')
                     }else{
                        this.$message.success('修改成功')
                        this.removeConsIds = []
                        this.removeMemberIds = []
                        this.$emit('getRoomCalendars')  // 刷新 房价日历页 即祖组件
                     }
                     this.closeDrawer()
                     this.$emit('Refresh')           // 刷新父组件
                  }
               })
            }else{
               console.log('error submit!!');
               return false;
            }
         })
      },
      // 当tabs为钟点房
      changeTabs(){
         // 全日房
         if(this.activeName === 'FULL_TIME') {
            this.roomsList.forEach(item=>{
               if(item.startTime.indexOf(':') !== -1) item.startTime.substring(0,10)
            })
         } else{
            // 钟点房
            this.hourRoomsList.forEach(item=>{
               item.max = 24
            })
         }

         this.getallRoomPriceList(['price']) // 批量获取价格
         this.$emit('changeNotAllowed',this.activeName === 'HOUR_ROOM') // 当为钟点房时 房态日历手型禁用，反之可操作
      },

      // 手机变化时
      changeInput(){
         this.LevelBool = false
         if(this.ruleForm.mobile === '') return
         if(verificationRule.mobile(this.ruleForm.mobile)) return
         let params = {
           hotelId:this.hotelInfo.id,
           mobile:this.ruleForm.mobile
         }

         getLevelAndUnit(params).then(res=>{
            if(res.success){
               this.LevelBool = true
               this.LevelAndUnit = res.records
               if(!this.LevelAndUnit.hasOwnProperty('unitId')) this.memberPriceType = 'MEMBER_LEVEL'
            }
         })
      },
      // 一键改价 计算折扣
      changePrice(){
         this.getallRoomPriceList(['price','memberPriceType'],this.memberPriceType,this.LevelAndUnit) // 批量获取会员/协议单元价格
      },

      // 渠道发生变化时
      channelchange(){
         this.getallRoomPriceList(['price']) // 批量获取价格
      },

      querySearch(queryString, cb) {
         if (this.ruleForm.channel==='AGREEMENT') {
            let data = {
               hotelId:this.hotelId,
               unitName:this.Keyword
            }
            getAgreementUnitByName(data).then(({success,records})=>{
               if (success) {
                  this.LevelAndUnit = records
                  for (let index = 0; index < records.length; index++) {
                     records[index].value = records[index].unitName;
                  }
                  let results = queryString ? records.filter(this.createStateFilter(queryString)) : records;
                  // 调用 callback 返回建议列表的数据
                  cb(results);
               }
            })
         } else {
            let data = {
               hotelId:this.hotelId,
               keyword:this.Keyword
            }
            getMemberByKeyword(data).then(({success,records})=>{
               if (success) {
                  for (let index = 0; index < records.length; index++) {
                     records[index].value = `${records[index].realName===undefined ? '-':records[index].realName}/${records[index].nickName===undefined ? '-':records[index].nickName}/${records[index].mobile===undefined ? '-':records[index].mobile}`;
                  }
                  let results = queryString ? records.filter(this.createStateFilter(queryString)) : records;
                  // 调用 callback 返回建议列表的数据
                  cb(results);
               }
            })
         }
      },
      createStateFilter(){
         return (state)=>{
            return state;
         };
      },
 
      // 确认搜索
      confirmSearch(){
         if (this.ruleForm.channel==='AGREEMENT') {
            this.memberPriceType = 'AGREEMENT_UNIT'
            let records = this.LevelAndUnit
            let obj = {}
            for (let index = 0; index < records.length; index++) {
               if (records[index].unitName === this.Keyword) {
                  obj.unitId = records[index].id
                  obj.isDelete = records[index].isDelete
                  obj.templateId = records[index].templateId
                  obj.unitName = records[index].unitName
               }
            }
            this.LevelAndUnit = obj
            this.changePrice()
            this.selectShow = true
         } else {
            let arr = this.Keyword.split('/')
            this.ruleForm.realName = arr[0]==='-' ? (arr[1]==='-' ? '散客' : arr[1]) : arr[0]
            this.ruleForm.mobile = arr[2] === '-' ? '' : arr[2]
            this.Keyword = ''
            this.changeInput()
         }
         this.dialogMemberSearch = false
      },

      // 房间信息-添加房间
      roomsAdd(){
         let startTime = ''
         // 钟点房
         if(this.activeName === 'FULL_TIME') {
            if (this.roomsList.length && this.roomsList[0].startTime) startTime = this.roomsList[0].startTime
            else startTime = dateFactory.getDistanceToday(0, false)
         }
         let row = {
            startTime: startTime,       // 入住日期
            endTime:'',
            duration:1,          // 天数
            rooms:[],            // 房型房号
            price:'',            // 价格
            keys:'',             // 房间号id+房间号+日期
            roomRate:''
         }
         // 全日房
         if(this.activeName === 'FULL_TIME') {
            this.roomsList.push(row)
            // if(this.bookIds !== 'add') this.$emit("selectCol",row, '+') // 添加不可能添加keys
         }else{
            // 钟点房 添加
            this.hourRoomsList.push(row)
         }
      },
      // 房间信息-删除房间
      roomsDel(row,rowIndex){
         // 钟点房
         if(this.activeName !== 'FULL_TIME') return  this.hourRoomsList.splice(rowIndex,1)
         // 全日房
         this.roomsList.splice(rowIndex,1)
         let arrkeys = this.getRoomsListKeys(this.roomsList)
         this.$emit("spliceCol",arrkeys)
      },

      // 房间信息-日期/房型cascader发生变化时
      changeCombin(val,rowIndex,row){
         let oldData = JSON.parse(JSON.stringify(row))
         if(!Array.isArray(val)){
            this.getCascader(["price","occupy",'date-occupy'],row,rowIndex,oldData)
         }else{
            this.getCascader(["price"],row)
         }

         // 全日房
         if(this.activeName === 'FULL_TIME'){
            // 时间发生变化时 需要更改结束日期
            if(!Array.isArray(val)) this.roomsList[rowIndex].endTime = this.getAfterDay(row.startTime,row.duration-1,'+',false)
            let arrkeys = this.getRoomsListKeys(this.roomsList)
            this.$emit("spliceCol",arrkeys)
         }
      },
      // 房型cascader展开时触发 处理cascader被占用不可选
      visibleChange(bool,row){
         if(bool){
            this.getCascader(['cascader-occupy','occupy'],row) // ["occupy"]
            // if(this.activeName === 'FULL_TIME') this.visibleCancel(row)
         }
      },
      // 房间信息-入住天数+/-时
      changeDuration(currentValue, oldValue,row,rowIndex){
         let symbol = '-'
         let num = 1
         if(currentValue > oldValue){
            symbol = '+'
            num = currentValue -oldValue
         } else if (oldValue > currentValue) {
            symbol = '-'
            num = oldValue - currentValue
         }
         // 获取 roomTypeId_roomNo
         let roomsKey = ''
         if(this.bookIds === 'add'){
            // roomsKey = row.rooms[0] + '_' + this.mapT.get(row.rooms[1])
            roomsKey = row.rooms[1]
         }else{
            // if (row.hasOwnProperty('roomTypeId')) roomsKey = row.roomTypeId + '_' + row.roomNo
            // else roomsKey = row.rooms[0] + '_' + this.mapT.get(row.rooms[1])
           roomsKey = row.rooms[1]
         }

         // 全日房
         if(this.activeName === 'FULL_TIME'){
            let keys = ''
            let cascaderArr = ['price']
            if(symbol === '+'){
               row.endTime = dateFactory.getDistanceAppoint(row.endTime ? row.endTime : row.startTime, '+', num, false)
               // row.endTime = dateFactory.addDays(row.startTime,row.duration)
               keys = roomsKey + '_' + row.endTime
               cascaderArr = ['price','symbol-occupy','occupy']
            }else{
               let delTime = row.endTime
               row.endTime = dateFactory.getDistanceAppoint(row.endTime ? row.endTime : row.startTime, '-', num, false)
               // row.endTime = dateFactory.addDays(row.startTime,row.duration)
               keys = roomsKey + '_' + delTime
               cascaderArr = ['price']
            }
            if(row.rooms.length) this.getCascader(cascaderArr,row,rowIndex)  // 价格price，是否占用occupy

            if (num === 1) {
               if(row.keys) this.$emit('setArrkeys',symbol,keys)
            } else {
               let arrkeys = this.getRoomsListKeys(this.roomsList)
               this.$emit("spliceCol",arrkeys)
            }
         }else{
            // 钟点房  当有日期
            if(row.startTime && row.rooms.length) this.getCascader(['price'],row,rowIndex)  // 价格price，是否占用 occupy
         }
      },

      // 批量请求 获取房间价格
      getallRoomPriceList(stateArr,memberPriceType,LevelAndUnit){
         if(this.activeName === 'FULL_TIME'){
            this.roomsList.forEach(item=>{
               if(item.rooms) this.getCascader(stateArr,item,memberPriceType,LevelAndUnit)
            })
         }else{
            this.hourRoomsList.forEach(item=>{ if(item.startTime && item.rooms) this.getCascader(stateArr,item,memberPriceType,LevelAndUnit)})
         }
      },
      // 请求 房型房号cascader和已有状态
      getRoomPriceList(param){
         return new Promise((resolve,reject)=>{
            getRoomPriceList(param).then(res=>{
               if(res.success) resolve(res.records)
            }).catch(err=>{
               reject(err)
            })
         })
      },
      // 获取 房型房号cascader和已有状态
      async getCascader(arrState,row,rowIndex,oldData){
         /*
          price            处理总价
          onlyCascader     只处理房态
          occupy           处理占用
          cascader-occupy  处理cascader占用
          symbol-occupy    处理+-时的占用验证
          date-occupy      处理日期发生变化时是否占用
          memberPriceType  会员/协议单元
         */
         let param = {
            hotelId:this.hotelId,
            orderSource:this.ruleForm.channel
         }

         if(arrState.indexOf('onlyCascader') === -1){
            param.beginDate = row.startTime.indexOf(":") === -1 ? row.startTime : row.startTime.substring(0,10)
            param.duration = row.duration
         }

         // 会员/协议单元
         if(arrState.indexOf('memberPriceType') !== -1){
            param = {
               hotelId:this.hotelId,
               orderSource:this.ruleForm.channel,
               beginDate: row.startTime.indexOf(":") === -1 ? row.startTime : row.startTime.substring(0,10),
               duration: row.duration,
               memberPriceType: rowIndex,
               ...oldData
            }
         }

         if(this.activeName !== 'FULL_TIME') param.checkInType = "HOUR_ROOM"
         await this.getRoomPriceList(param).then(resData=>{
            let checkInType = 'hourRoomsList'                                 // 钟点房
            if(this.activeName === 'FULL_TIME') checkInType = 'roomsList'     // 全日房

            // 处理总价 price
            if(arrState.indexOf('price') !== -1){
               if(row.hasOwnProperty('rooms')){
                  resData.forEach(item=>{
                     if(item.id === row.rooms[0]) row.roomRate = item.roomTypePrice // 总价
                  })
               }
            }

            // 获取cascader 并且被占用数据初始化
            this.roomOptions = []
            resData.forEach((item,index)=>{
               this.mapT.set(item.id,item.roomTypeName)
               this.roomOptions.push({
                  value: item.id,
                  label: item.roomTypeName,
                  children: [{value:'',label:'未排房',roomPrice:item.roomTypePrice}],
               })
               item.rooms.forEach(items=>{
                  this.mapT.set(items.id,items.roomNo)
                  this.roomOptions[index].children.push({
                     value: items.id ,
                     label: items.roomNo,
                     roomPrice: items.roomPrice,
                     disabled:false,      // 初始化可选 true为禁选
                  })
               })
            })

            // 处理占用
            if(arrState.indexOf('occupy') !== -1){
               let existingStatesArr = this.getRoomsListKeys(this[checkInType])   // 房间信息中所有keys
               // let currentIndex = 0                                            // 房间信息中第n行 下标
               let currentIndexStatesArr = []                                     // 房间信息中第n行的所有keys
               let outsideStatesArr = []                                          // 除房间信息中第n行之外，且为当前行日期中的所有keys

               let dateRange = dateFactory.getDateRange(row.startTime,row.endTime ? row.endTime : row.startTime) // 为了解决当点击的数据没有选房型房号，也就没有row.endTime时所作的处理

               if(arrState.indexOf("cascader-occupy") !== -1){
                  this[checkInType].forEach((item,index)=>{
                     if(item.startTime === row.startTime){
                        // currentIndex = index
                        if(row.rooms.length !== 0 && item.rooms[1] === row.rooms[1]) currentIndexStatesArr = this.getRoomsListKeys([row]) // 获取房间信息中第n行的所有keys
                     }
                  })

                  existingStatesArr.forEach(item=>{
                     if(!currentIndexStatesArr.includes(item)){ // 获取 除房间信息中第n行之外的所有keys
                        // 获取 除房间信息中第n行之外，且为当前行日期中的所有keys
                        // let indexNum = search_substr_pos(item,'_')
                        let indexNum = item.indexOf('_') + 1
                        dateRange.forEach(dateRangeItem=>{
                           // if(dateRangeItem === item.substring(indexNum[1]+1)) outsideStatesArr.push(item)
                           if(dateRangeItem === item.substring(indexNum)) outsideStatesArr.push(item)
                        })
                     }
                  })
               }

               // 用户动态已选择的被占用数据处理 symbol-occupy
               if(arrState.indexOf("symbol-occupy") !== -1 || arrState.indexOf("date-occupy") !== -1){
                  let duplicateValue = getDuplicate(existingStatesArr,'value')
                  if(duplicateValue.length){
                     let duplicateArr = []
                     duplicateValue.forEach(item=>{
                        let indexNum = search_substr_pos(item,'_')
                        duplicateArr.push(`${this.mapT.get(item.substring(0,indexNum[0]))}-${item.substring(indexNum[0]+1,indexNum[1])}`)
                     })
                     if(arrState.indexOf("symbol-occupy") !== -1) this.verificationRepeat(duplicateArr,row,arrState)
                     if(arrState.indexOf("date-occupy") !== -1){
                        this.verificationRepeat(duplicateArr,row,arrState,rowIndex,oldData)
                     }
                  }
               }

               resData.forEach((item,index)=>{
                  item.rooms.forEach(items=>{
                     // 后台返回的被占用数据处理
                     if(items.hasOwnProperty('isUse')){
                        this.roomOptions[index].children.forEach(childItem=>{
                           if(items.id === childItem.value) {
                              // cascader-occupy
                              if(this.bookIds === 'add' || this.bookIds.length === 18){
                                 if(arrState.indexOf("cascader-occupy") !== -1) childItem.disabled = items.isUse
                              }
                              // symbol-occupy
                              if(arrState.indexOf("symbol-occupy") !== -1 || arrState.indexOf("date-occupy") !== -1){
                                 if(this.bookIds === 'add') {
                                    if(items.id === row.rooms[1]) this.verificationRepeat([`${this.mapT.get(items.roomTypeId)}-${this.mapT.get(items.id)}`],row,arrState,rowIndex,oldData)
                                 }
                              }
                           }
                        })
                     }

                     // 用户动态已选择的被占用数据处理 cascader-occupy
                     if(arrState.indexOf("cascader-occupy") !== -1 && outsideStatesArr.length>29){
                        outsideStatesArr.forEach(outsideItem=>{
                           // let indexNum = search_substr_pos(outsideItem,'_')
                           let indexNum = outsideItem.indexOf('_')
                           if(outsideItem.substring(0,indexNum) === items.id){ // 验证房间号
                              this.roomOptions[index].children.forEach(childItem=>{
                                 if(items.id === childItem.value) childItem.disabled = true
                              })
                           }
                           // if(outsideItem.substring(0,indexNum[0])=== items.roomTypeId){ // 验证房型
                              // if(outsideItem.substring(indexNum[0]+1,indexNum[1]) === items.roomNo){ // 验证房间号
                              //    this.roomOptions[index].children.forEach(childItem=>{
                              //       if(items.id === childItem.value) childItem.disabled = true
                              //    })
                              // }
                           // }
                        })
                     }

                  })
               })
            }

         })
      },
      // 验证是否被占用
      verificationRepeat(roomsArr,row,arrState,rowIndex,oldValue){
         this.$alert(`
            <div style="display: flex">
               <div style="width: 25px;height: 25px;display: inline-block;background-color: red;border-radius: 20px;color: white;margin-right: 5px;text-align: center;">×</div>
               <div style="width: 80%;">
                  <div>请注意！以下房间存在库存冲突，请重新选择。</div>
                  <div style="color: red;">冲突房间：</div>
                  <span style="font-weight: bold">${roomsArr.join("、")}</span>
               </div>
           </div>`, '冲突提示', {
            dangerouslyUseHTMLString: true,
            confirmButtonText: '确定',
            callback: action => {
               let indexNum = search_substr_pos(row.keys)

               // symbol-occupy 处理+-时的占用验证占用
               if(arrState.indexOf("symbol-occupy") !== -1){
                  this.$emit('setArrkeys','-',row.keys.substring(0,indexNum[1]) + '_' + row.endTime)
                  row.endTime = dateFactory.getDistanceAppoint(row.endTime, '-', 1, false)
                  row.duration = row.duration-1
                  this.getCascader(["price"],row)
               }
               // date-occupy 处理日期发生变化时是否占用
               if(arrState.indexOf("date-occupy") !== -1){
                  // 全日房
                  if(this.activeName === 'FULL_TIME'){
                     this.roomsList[rowIndex].startTime = this.getAfterDay(oldValue.endTime,oldValue.duration-1,'-',false)
                     this.roomsList[rowIndex].endTime = oldValue.endTime
                     let arrkeys = this.getRoomsListKeys(this.roomsList)
                     this.$emit("spliceCol",arrkeys)
                     this.getCascader(["price"],row)
                  }else{
                     console.log('钟点房')
                  // 钟点房
                  }
               }
            }
         });
      },

      // 获取arr中所有keys值
      getRoomsListKeys(arr){
         let arrkeys = []
         arr.forEach(item=>{
            if(item.rooms.length){
               let dateRange = dateFactory.getDateRange(item.startTime,item.endTime ? item.endTime : item.startTime)
               dateRange.forEach((dateItem,dateIndex)=>{
                  // let roomNo = this.mapT.get(item.rooms[1])
                  // if(!dateIndex) item.keys = item.rooms[0]+'_'+roomNo+'_'+dateItem
                  // arrkeys.push(item.rooms[0]+'_'+roomNo+'_'+dateItem)
                  if(!dateIndex) item.keys = item.rooms[1]+'_'+dateItem
                  arrkeys.push(item.rooms[1]+'_'+dateItem)
               })
            }
         })
         return arrkeys
      },

      // 添加编辑 入住人抽屉
      checkinAdd(item){
         // 添加
         if(item.hasOwnProperty('keys')){
            let indexNum = search_substr_pos(item.keys,'_')
            // 当前入住人房间信息
            this.currentCheckinRoom = {
               startTime:item.startTime.substring(5),
               endTime:'',
               duration:item.duration,
               rooms:item.keys.substring(indexNum[0]+1,indexNum[1]),
               roomType:this.mapT.get(item.rooms[0]),
               roomRate:item.roomRate,
               unit:this.activeName === 'FULL_TIME' ? '晚' : '小时',
               price :item.price,
               keys:item.keys,
            }
         }else{ // 编辑
            this.currentCheckinRoom = {
               startTime:item.startTime.substring(5,10),
               endTime:'',
               duration:item.duration,
               rooms:item.roomNo,
               roomType:this.mapT.get(item.roomTypeId),
               roomRate:item.roomRate,
               unit:item.checkInType === 'FULL_TIME' ? '晚' : '小时',
               id:item.id
            }
         }
         this.checkinList = item.checkinMemberList
         this.checkInpersonBool = true
      },
      // 关闭入住人 抽屉
      checkinClose(val){
         this.checkInpersonBool = val
         this.checkinList = [{
            realName:'',
            mobile:'',
            certificateType:'',  // 证件类型
            certificateNo:'',    // 证件号
         }]
      },
      // 完成入住人 抽屉
      handleFinish(val,bool,key,removeIds){
         let checkInType = 'hourRoomsList'                                 // 钟点房
         if(this.activeName === 'FULL_TIME') checkInType = 'roomsList'     // 全日房

         this[checkInType].forEach(item=>{
            if(item.hasOwnProperty('keys')) if(item.keys === key) item.checkinMemberList = val
            if(item.hasOwnProperty('id')){
               if(item.id === key) item.checkinMemberList = val
               this.removeMemberIds = removeIds
            }
         })
         this.checkinClose(bool)
      },

      // 获取消费项 下拉数据
      getListConsumptionItems(){
         getListConsumptionItem({hotelId: this.hotelId,state:'ENABLED'}).then(res=>{
            if(res.success) {
               // this.consumeOptions = res.records
               let options = [
               {
                  value: '1',
                  label: '早餐消费',
                  children: []
               },
               {
                  value: '2',
                  label: '客房消费',
                  children: []
               },
               {
                  value: '3',
                  label: '赔偿',
                  children: []
               },
               {
                  value: '4',
                  label: '其他',
                  children: []
               },
               ]
               res.records.forEach(item=>{
                  if(item.categoryCode == 1){
                     options[0].children.push({ value: item.id,label: item.name,price:item.price   })
                  } else if(item.categoryCode == 2){
                     options[1].children.push({ value: item.id,label: item.name,price:item.price   })
                  } else if(item.categoryCode == 3){
                     options[2].children.push({ value: item.id,label: item.name,price:item.price   })
                  } else if(item.categoryCode == 4){
                     options[3].children.push({ value: item.id,label: item.name,price:item.price   })
                  }
               })
               this.consumeOptions = options
            }
         })
      },
      // 消费项目发生变化时 金额赋值
      consumptionProject(val,row){
         // this.consumeOptions.forEach(item=>{
         //    if(item.id === val){
         //       row.price = item.price
         //       row.amount = row.price * row.quantity
         //    }
         // })
         row.consumptionItemName = val[1]
         this.consumeOptions[val[0]-1].children.forEach(item=>{
            if(item.value === val[1]){
               row.price = item.price
               row.amount = row.price * row.quantity
            }
         })
      },
      // 消费数量发生变化时 总金额计算
      consumptionQuantity(val,row){
         row.amount = row.price * row.quantity
      },
      // 添加消费
      consumeAdd(bool){
         if(bool) this.getListConsumptionItems()
         this.consumptionDTOList.push({
            consumptionItemName:'',   // 项目
            quantity:1,               // 数量
            consumptionTime:dateFactory.dateFormat(),      // 日期
            amount:'',                // 单价*数量
            price:'',                 // 单价
            remark:'',
         })
      },
      // 删除消费
      consumeDel(item,index){
         if(this.bookIds !== 'add') this.removeConsIds.push(item.id)
         this.consumptionDTOList.splice(index,1)
      },

      // 添加收款
      collectionAdd(){
         this.collectionList.push({
            paymentMethod:'', // 支付方式
            itemType:'',      // 项目类型  PROCEEDS 收款,DEPOSIT_RECEIVE 收押金
            amount:0,         // 金额
            payTime:dateFactory.dateFormat(false),       // 支付时间
            remark:'',        // 备注
            // refundType:'',    // 退款方式 OFFLINE线下退款,ONLINE 线上退款
            // authCode:'',      // 支付授权码
            // paymentType:'',   // 支付类型 MICROPAY 扫码支付
         })
      },
      // 删除收款
      collectionDel(item,index){
         this.collectionList.splice(index,1)
      },

      // 添加提醒
      warnAdd(){
        this.warnState = 'add'
        this.warnBool = true
      },
      // 编辑提醒
      warnEdit(item,index){
         this.warnState = 'edit'
         this.warnIndex = index
         this.warnRuleForm = item
         this.warnBool = true
      },
      // 删除提醒
      warnDel(item,index){
         this.orderReminderList.splice(index,1)
      },

      // 关闭提醒 抽屉
      warnClose(val){
         this.warnBool = val
         this.warnRuleForm = {}
      },
      // 完成提醒 抽屉
      warnFinish(formName){
         this.$refs[formName].validate((valid) => {
            if (valid) {
               if(this.warnState === 'add') this.orderReminderList.push(this.warnRuleForm)
               else this.orderReminderList.splice(this.warnIndex,1,this.warnRuleForm)
               this.warnClose(false)
            }else{
               console.log('error submit!!');
               return false;
            }
         })
      },

      // 关闭制卡抽屉
      closeCardDrawer(bool) {
         this.visibleCard = bool
      },
      // 处理制卡
      handleBusinessCard(row) {
        if (!row.checkinMemberList || row.checkinMemberList.length === 0) {
          return this.$message.error('请选择入住人！')
        }
        this.visibleCard = true
        this.$refs.businessCard.getRoomInfo(row)
        this.$refs.businessCard.getByBrandCode()
      },

      // 设置日期限制
      disable_pickerOptions(times){
         let date = new Date()
         let minutes = date.getMinutes() + 1
         // h和m 是将日期只取时分
         let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
         let m = (minutes < 10 ? '0' + minutes : minutes);
         // pickerDate 判断当前选择时间是否等于上条数据时间
         // 若等于时，限制时间从00：00：00 - 上条数据开始时间，不等于时，24小时可选
         // let pickerDate = date.toDateString() === new Date(times).toDateString()
         return {
            // 日期限制
            disabledDate: (time) => {
               let old = new Date()
               return time.getTime() < new Date(old).getTime() - 86400000
            },
            // 时间限制
            // selectableRange: pickerDate? h + m  + ':00 - 23:59:59' : '12:59:59 - 23:59:59'
            selectableRange: h + m  + ':00 - 23:59:59'
         }
      },

      /*
      * 获取n小时前后时间
      * days 开始时间-yyyy-MM-dd HH:mm:ss
      * hours n小时
      * state +加/-减
      * 返回格式yyyy-MM-dd HH:mm:ss
      * */
      getAfterHour(days,hours = 0 ,state = '+'){
         let newDays = new Date(days)
         if(state === '+') return this.dateFormat(newDays.setHours(newDays.getHours() + hours))
         if(state === '-') return this.dateFormat(newDays.setHours(newDays.getHours() - hours))
      },
      getAfterDay(days,daysNum,state = '+',bool){
         let newDays = new Date(days)
         if(state === '+') return this.dateFormat(newDays.setDate(newDays.getDate() + daysNum),bool)
         if(state === '-') return this.dateFormat(newDays.setDate(newDays.getDate() - daysNum),bool)
      },
      // 时间戳 
      dateFormat(days,state = true){
         let myDate = new Date(days);
         let year =  myDate.getFullYear();
         let month = myDate.getMonth() + 1 < 10 ? "0"+(myDate.getMonth() + 1) : myDate.getMonth() + 1;
         let date = myDate.getDate() < 10 ? "0"+myDate.getDate() : myDate.getDate();
         let hours = myDate.getHours() < 10 ? "0"+myDate.getHours() : myDate.getHours();
         let minutes = myDate.getMinutes() < 10 ? "0"+myDate.getMinutes() : myDate.getMinutes();
         let seconds = myDate.getSeconds() < 10 ? "0"+myDate.getSeconds() : myDate.getSeconds();

         if(isNaN(year)) return "无"
         else if(state) return  year + '-' + month + '-' + date + ' ' + hours + ':' + minutes +':' + seconds
         else if(!state) return year + '-' + month + '-' + date
      },
   }
}
</script>

<style scoped lang="scss">
$border-grey:#eff0f5;
.addAtrialstate_mask{
   position: fixed;
   top: 0px;
   left: 0px;
   width: 64%;
   height: 100vh;
   z-index: 10;
   background-color: gray;
   opacity:0.3;
}

.addAtrialstate{
   transition: right 0.6s ease;
   background-color: white;
   width: 36%;
   height: 100vh;
   position: fixed;
   //right: 0;
   top: 0;
   box-shadow: 0 0 18px -10px black;
   z-index: 2001;

   .float-r { float: right}
   .border-b{border-bottom: 1px solid $border-grey;}

   .drawer-header{
      display: flex;
      justify-content: space-between;
      height: 65px;
      line-height: 65px;
      ::v-deep .el-page-header {
         line-height: unset !important;
      }
      padding: 0 10px;
   }

   .drawer-main{
      height: calc( 100vh - 130px );
      // 基本信息
      .basicInfo {
         .form-basicInfo{
            display: flex;
            flex-wrap: wrap;
            .el-form-item{
               width: 50%;
            }
         }
      }
      // 房间信息
      .combinInfo{
         ::v-deep .input-combin{
            .el-input-group__prepend {
               padding: 0;
               .datePicker{ margin-left: 1px; }
               .el-input__inner{
                  height: 37px;
                  border: 0 solid transparent;
                  background-color: #fff;
               }
               .el-input-number{
                  line-height: 37px;
                  .el-input-number__increase, .el-input-number__decrease{
                     //top:0!important;
                     border-radius: 0;
                  }
               }
            }
         }
         ::v-deep .el-input-group--prepend .el-input__inner{
            height: 42px;
         }
         .badge-checkin{
            color: #0c7ffd;
            margin: 0 20px 0 10px;
            cursor: pointer;
         }
      }

      // 收款信息
      .warnInfo {
        ::v-deep .warn-item{
           display: inline-block;
            width: 540px;
            height: 40px;
            line-height: 40px;
            background-color: $border-grey;
            font-size: 15px;
            color: #606266;
         }
      }
   }
}


.checkin-main{
   //margin: 0 20px;
   .roomsInfo{
      padding: 0 20px;
      text-align: center;
      background-color:#fafbfe;
      height: 45px;
      line-height: 45px;
      color: #99a9bf;
   }
   .content{
      padding: 0 20px;
      ::v-deep .input-combin{
         .el-input-group__prepend {
            padding: 0;
            border-left: 0px solid transparent;
            width: 355px;
            .el-input__inner{
               height: 37px;
               border-right: 0px solid transparent;
               border-top: 0px solid transparent;
               border-bottom: 0px solid transparent;
               background-color: #fff;
            }
            .el-select{
               border-left: 1px solid #DCDFE6;
               border-right: 1px solid #DCDFE6;
            }
            //.el-input-number{
            //   line-height: 37px;
            //   .el-input-number__increase, .el-input-number__decrease{
            //      //top:0!important;
            //      border-radius: 0;
            //   }
            //}
         }
      }

   }
}

.drawer-footer{
   width: 100%;
   height: 65px;
   line-height: 65px;
   background-color: white;
   position: absolute;
   bottom: 0px;
   border-top: 1px solid $border-grey;
   padding: 0 10px;
}
.RMB{
   position: relative;
   top: 12px;
   left: 7px;
   color: #333;
}
</style>
<style>

.atrial-scrollbar .el-scrollbar__wrap{ height: 104%;}
</style>
