import request from "@/api/service";

const api={
  // 查询支付方式列表
  getModePaymentList:'/pms/hotelPayment/getList',
  // 添加支付方式
  addModePayment:'/pms/hotelPayment/insert',
  // 编辑支付方式
  editModePayment:'/pms/hotelPayment/update',
  // 编辑支付方式
  deleteModePayment:'/pms/hotelPayment/remove',
  // 排序支付方式
  sortModePayment:'/pms/hotelPayment/sort',
  // 设置状态 支付方式
  setModePaymentstatus:'/pms/hotelPayment/setStatus',
  // 查询激活列表
  getEnabledList:'/pms/hotelPayment/getEnabledList'
}

// 查询支付方式列表
export function getModePaymentList (data) {
  return request({
     url: api.getModePaymentList,
     method: 'post',
     data,
  })
}

// 添加支付方式
export function addModePayment (data) {
  return request({
     url: api.addModePayment,
     method: 'post',
     data,
  })
}

// 编辑支付方式
export function editModePayment (data) {
  return request({
     url: api.editModePayment,
     method: 'post',
     data,
  })
}

// 编辑支付方式
export function deleteModePayment (data) {
  return request({
     url: api.deleteModePayment,
     method: 'post',
     data,
  })
}

// 排序支付方式
export function sortModePayment (data) {
  return request({
     url: api.sortModePayment,
     method: 'post',
     data,
  })
}

// 设置状态 支付方式
export function setModePaymentstatus (data) {
  return request({
     url: api.setModePaymentstatus,
     method: 'post',
     data,
  })
}

// 查询激活列表
export function getEnabledList (data) {
  return request({
     url: api.getEnabledList,
     method: 'post',
     data,
  })
}