export const A4paperCss = '<style scoped lang="scss">\n' +
   '* {\n' +
   'box-sizing: border-box;\n' +
   'page-break-inside: avoid;\n' +
   '}\n' +
   '\n' +
   'body {\n' +
   'font-size: 14px;\n' +
   'padding: 12px 20px;\n' +
   'font-family: PingFangSC-Regular, PingFang SC;\n' +
   'line-height: 20px;\n' +
   '}\n' +
   '\n' +
   '.auto-break {\n' +
   'page-break-inside: auto;\n' +
   '}\n' +
   '\n' +
   '@media print {\n' +
   '@page {\n' +
   'margin: 1cm;\n' +
   '}\n' +
   '}\n' +
   '\n' +
   '.ntw-name {\n' +
   'font-size: 16px;\n' +
   'font-family: PingFangSC-Medium, PingFang SC;\n' +
   'font-weight: 500;\n' +
   'font-weight: 500;\n' +
   'line-height: 22px;\n' +
   '}\n' +
   '\n' +
   '.title {\n' +
   'font-size: 18px;\n' +
   'font-family: PingFangSC-Medium, PingFang SC;\n' +
   'font-weight: 500;\n' +
   'line-height: 25px;\n' +
   'text-align: center;\n' +
   'padding: 24px 0 32px;\n' +
   '}\n' +
   '\n' +
   '.user-info {\n' +
   'font-size: 12px;\n' +
   'font-family: PingFangSC-Regular, PingFang SC;\n' +
   'font-weight: 400;\n' +
   'line-height: 17px;\n' +
   'padding-bottom: 8px;\n' +
   '}\n' +
   '\n' +
   '.border-bottom {\n' +
   'border-bottom: 1px dashed #333333;\n' +
   '}\n' +
   '\n' +
   '.border-top {\n' +
   'border-top: 1px dashed #333333;\n' +
   '}\n' +
   '\n' +
   '.flex-center-center {\n' +
   'display: flex;\n' +
   'justify-content: center;\n' +
   'align-items: center;\n' +
   '}\n' +
   '\n' +
   '.form-title {\n' +
   'font-size: 12px;\n' +
   'font-family: PingFangSC-Medium, PingFang SC;\n' +
   'font-weight: 500;\n' +
   'line-height: 17px;\n' +
   'margin: 24px 0 8px;\n' +
   '}\n' +
   '\n' +
   '.form-content {\n' +
   'width: 100%;\n' +
   'page-break-inside: auto;\n' +
   'border-spacing: 0;\n' +
   'border-collapse: collapse;\n' +
   '}\n' +
   '\n' +
   'th,\n' +
   'td {\n' +
   'min-width: 50px;\n' +
   'height: 32px;\n' +
   'padding: 6px 8px;\n' +
   'text-align: left;\n' +
   'font-weight: 400;\n' +
   'border: 1px solid #dddddd;\n' +
   '}\n' +
   '\n' +
   'th span {\n' +
   'margin-right: 40px;\n' +
   '}\n' +
   '\n' +
   'thead {\n' +
   'page-break-inside: avoid;\n' +
   '}\n' +
   '\n' +
   '.nowrap {\n' +
   'white-space: nowrap\n' +
   '}\n' +
   '\n' +
   '.wrap {\n' +
   'white-space: pre-wrap;\n' +
   'word-break: break-all\n' +
   '}\n' +
   '\n' +
   '.w-full {\n' +
   'width: 100%\n' +
   '}\n' +
   '\n' +
   '.w-110 {\n' +
   'width: 110px\n' +
   '}\n' +
   '\n' +
   '.pt-24 {\n' +
   'padding-top: 24px;\n' +
   '}\n' +
   '\n' +
   '.pb-24 {\n' +
   'padding-bottom: 24px;\n' +
   '}\n' +
   '\n' +
   '.max-120 {\n' +
   'max-width: 120px;\n' +
   '}\n' +
   '\n' +
   '.mt-8 {\n' +
   'margin-top: 8px;\n' +
   '}\n' +
   '\n' +
   '.no-border {\n' +
   'border: none;\n' +
   '}\n' +
   '\n' +
   '.text-right {\n' +
   'text-align: right;\n' +
   '}\n' +
   '\n' +
   '.text-16-bold {\n' +
   'font-size: 18px;\n' +
   'font-family: PingFangSC-Medium, PingFang SC;\n' +
   'font-weight: 500;\n' +
   'line-height: 25px;\n' +
   '}\n' +
   '\n' +
   '.table-row {\n' +
   'font-family: PingFangSC-Medium, PingFang SC;\n' +
   'font-weight: 500;\n' +
   'height: 32px;\n' +
   '}\n' +
   '\n' +
   '.state {\n' +
   'margin-top: 24px;\n' +
   'min-height: 209px;\n' +
   'background: #FFFFFF;\n' +
   'border: 1px solid #DDDDDD;\n' +
   'position: relative;\n' +
   'padding: 24px 16px 50px;\n' +
   'font-family: PingFangSC-Medium, PingFang SC;\n' +
   'font-weight: 500;\n' +
   '}\n' +
   '\n' +
   '.state__tabloid {\n' +
   'padding: 8px;\n' +
   'background: #FFFFFF;\n' +
   'border: 1px solid #333;\n' +
   'font-family: PingFangSC-Medium, PingFang SC;\n' +
   'font-weight: 500;\n' +
   '}\n' +
   '\n' +
   '.state--no-border {\n' +
   'min-height: 0;\n' +
   'border: none;\n' +
   'padding: 0;\n' +
   'margin-top: 72px;\n' +
   '}\n' +
   '\n' +
   '.state--no-border sign {\n' +
   'height: 0;\n' +
   '}\n' +
   '\n' +
   '.state span {\n' +
   'margin-right: 8px;\n' +
   'display: inline-block;\n' +
   '}\n' +
   '\n' +
   '.state .pre-line {\n' +
   'height: 20px;\n' +
   '}\n' +
   '\n' +
   '.state .pre-space {\n' +
   'display: inline-block;\n' +
   'width: 5px;\n' +
   'margin: 0\n' +
   '}\n' +
   '\n' +
   '.sign {\n' +
   'position: absolute;\n' +
   'bottom: 0;\n' +
   'right: 0;\n' +
   'height: 50px;\n' +
   '}\n' +
   '\n' +
   '.line {\n' +
   'width: 90px;\n' +
   'border-bottom: 1px solid #DDDDDD;\n' +
   '}\n' +
   '\n' +
   '.state__tabloid .line {\n' +
   'width: 80px;\n' +
   'height: 1px;\n' +
   'display: block;\n' +
   'border-bottom: 1px solid #DDDDDD;\n' +
   '}\n' +
   '\n' +
   '.out-sign span {\n' +
   'margin-right: 8px;\n' +
   'display: inline-block;\n' +
   '}\n' +
   '\n' +
   '.footer {\n' +
   'margin-top: 8px;\n' +
   'font-size: 12px;\n' +
   'line-height: 17px;\n' +
   '}\n' +
   '\n' +
   '.footer span {\n' +
   'margin-right: 40px;\n' +
   '}\n' +
   '\n' +
   '.mr-16 {\n' +
   'margin-right: 16px;\n' +
   '}\n' +
   '\n' +
   '.ml-16 {\n' +
   'margin-left: 16px;\n' +
   '}\n' +
   '\n' +
   '.ml-24 {\n' +
   'margin-left: 24px;\n' +
   '}\n' +
   '\n' +
   '.flex-between-center {\n' +
   'display: flex;\n' +
   'justify-content: space-between;\n' +
   'align-items: center;\n' +
   '}\n' +
   '\n' +
   '.flex-between-start {\n' +
   'display: flex;\n' +
   'justify-content: space-between;\n' +
   'align-items: start;\n' +
   '}\n' +
   '\n' +
   '.flex-1 {\n' +
   'flex: 1\n' +
   '}\n' +
   '\n' +
   '.flex-end-end {\n' +
   'display: flex;\n' +
   'justify-content: end;\n' +
   'align-items: end;\n' +
   '}\n' +
   '\n' +
   '.p-24 {\n' +
   'padding: 24px;\n' +
   '}\n' +
   '\n' +
   '.pb-12 {\n' +
   'padding-bottom: 12px;\n' +
   '}\n' +
   '\n' +
   '.pt-12 {\n' +
   'padding-top: 12px;\n' +
   '}\n' +
   '\n' +
   '.mt-16 {\n' +
   'margin-top: 16px;\n' +
   '}\n' +
   '\n' +
   '.pt-4 {\n' +
   'margin-top: 4px;\n' +
   '}\n' +
   '</style>'
