<template>
  <div id="print-area-receipt">
    <h1 style="font-size: 16px; text-align: center">{{ hotelInfo.hotelName }}</h1>
    <div style="text-align: center; font-size: 16px; margin-top: 10px">临时住宿登记表</div>
    <div style="font-size: 14px">
      <span style="margin-right: 10px">预定人：{{ userInfo.realName }}</span>
      <span>电话：{{ userInfo.mobile }}</span>
    </div>
    <div style="margin-top: 10px">
      <p style="font-size: 14px; margin-bottom: 10px">房间信息#</p>
      <div v-for="(item, key) in checkinRoomDTOList" :key="key" style="display: flex; justify-content: space-between;
        margin-bottom: 10px; padding: 0 0 0 15px">
        <dl style="font-size: 13px">
          <dt>{{ item.roomTypeName }} {{ item.roomNo }}</dt>
          <dd style="padding: 0 0 0 10px">
            <p>入住时间：{{ item.startTime }}</p>
            <p>离店时间：{{ item.endTime }}</p>
          </dd>
        </dl>
        <p style="font-size: 14px">￥{{ item.roomRate }}</p>
      </div>
    </div>
    <div style="margin-top: 20px; padding: 0 0 20px 0; border-bottom: 1px solid #CCCCCC">
      <p style="font-size: 14px; margin-bottom: 10px">其他消费#</p>
      <div v-for="(item, key) in consumptionDTOList" :key="key" style="display: flex; justify-content: space-between;
        margin-bottom: 10px; padding: 0 0 0 20px">
        <dl style="font-size: 14px">
          <dt>
            <span style="margin-right: 5px">{{ item.consumptionItemName }}</span>
            <span>数量{{ item.quantity }}</span>
          </dt>
          <dd style="padding: 0 0 0 10px">
            <p>购买日期：{{ item.consumptionTime }}</p>
          </dd>
        </dl>
        <p style="font-size: 14px">￥{{ item.amount }}</p>
      </div>
    </div>
    <div style="margin-top: 20px; padding: 0 0 20px 0; border-bottom: 1px solid #CCCCCC">
      <p style="font-size: 14px; margin-bottom: 10px">收款信息#</p>
      <div v-for="(item, key) in collectionRefundDTOList" :key="key" style="display: flex; justify-content: space-between;
        margin-bottom: 10px; padding: 0 0 0 20px">
        <dl style="font-size: 14px">
          <dt>订单金额</dt>
          <dd style="padding: 0 0 0 10px">
            <p>支付方式：{{ item.paymentMethod | filterPayMethod(that) }}</p>
            <p>支付日期：{{ item.payTime }}</p>
          </dd>
        </dl>
        <p style="font-size: 14px">￥{{ item.amount }}</p>
      </div>
    </div>
    <div v-if="checked" style="margin-top: 20px; font-size: 14px">
      <p style="margin-bottom: 10px">订单备注#</p>
      <p>{{ userInfo.remark || '无' }}</p>
    </div>
    <div style="margin-top: 20px; border: 1px solid #CCCCCC; padding: 10px; font-size: 14px">
      {{ insertLineBreak  }}
      <p style="margin-top: 10px; text-align: right">
        <span style="margin-right: 10px">员工签字：_______</span>
        <span>客人签字：_______</span>
      </p>
    </div>
    <div style="margin-top: 20px; font-size: 14px">
      <p style="margin-bottom: 5px">打印时间：{{ currentTime }}</p>
      <p>操作人：{{ accountName }}</p>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { dateFactory } from '@/utils/dateFactory';
export default {
  name: 'A4paper',
  props: {
    userInfo: {
      type: Object,
      default: () => {}
    },
    checkinRoomDTOList: {
      type: Array,
      default: () => []
    },
    consumptionDTOList: {
      type: Array,
      default: () => []
    },
    collectionRefundDTOList: {
      type: Array,
      default: () => []
    },
    content: String,
    checked: Boolean
  },
  data () {
    return {
      that: this,
      currentTime: '',
      accountName: ''
    }
  },
  mounted() {
    this.accountName = JSON.parse(sessionStorage.getItem('userInfo') || '{}').userName
    this.currentTime = dateFactory.dateFormat(true)
  },
  computed: {
    ...mapState(['dictData','hotelInfo']),
    insertLineBreak() {
      let index = 0
      let resStr = ''
      while (index < this.content.length) {
        index ++
        if (index % 20 === 0) {
          resStr = resStr + '\n'
        } else {
          resStr = resStr + this.content.substr(index, 1)
        }
      }
      return resStr
    }
  },
  filters: {
    filterItemType(val, that) {
      return val
    },
    filterPayMethod(val, that) {
      if (val && that.dictData['pms-pay-method']) return that.dictData['pms-pay-method'][val] || val
    }
  }
}
</script>
