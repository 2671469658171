import request from "@/api/service";

const api = {
   content: '/pms/printConfig/getList',
   add: '/pms/printConfig/add',
   edit: '/pms/printConfig/edit'
}

export function savePrintSet(action, params) {
   return request({
      url: api[action],
      method: 'POST',
      data: params
   })
}

export function getContent(params) {
   return request({
      url: api.content,
      method: 'GET',
      params
   })
}
