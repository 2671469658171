import request from "@/api/service";
import qs from 'qs';

const api = {
   // 获取房态日历
   getRoomCalendar:'/pms/roomStatus/getRoomCalendar',
   // 添加预订单
   insertBookInfo:'/pms/bookInfo/insert',
   // 编辑预订单
   editInsertBookInfo:'/pms/bookInfo/modify',
   // 获取房态日历(单日)
   getRoomCalendarOneDay:'/pms/roomStatus/getRoomCalendarOneDay',

   // 房型排序
   setRoomTypeSort:'/pms/roomtype/setSort',
   // 房间号排序
   setRoomSort:'/pms/room/setSort',

   // 变更房态状态
   changPmsRoomState:'/pms/room/changPmsRoomState',
   // 批量添加关房
   addCloseRooms:'/pms/roomstate/closeRooms',
   // 批量删除关房
   delCloseRooms:'/pms/roomstate/delete/',

   // 根据订单id查询预订单详情
   getReserveDetail:'/pms/bookInfo/getDetail/',

   // 根据房型查找价格
   getRoomPriceList:'pms/roomStatus/getRoomPriceList',
   // 根据手机号查询会员信息
   getLevelAndUnit:'/member/memberHotel/getLevelAndUnit',

   // 会员搜索
   getMemberByKeyword:'/member/getMemberByKeyword',
   // 协议搜索
   getAgreementUnitByName:'/hotel/agreementUnit/getAgreementUnitByName',
}

// 获取房态日历
export function getRoomCalendar (data) {
   return request({
      url: api.getRoomCalendar,
      method: 'post',
      headers:{"responseType": 'JSON'},
      data,
   })
}

// 添加/编辑预订单
export function insertBookInfo (params,state) {
   return request({
      url: state === 'add' ? api.insertBookInfo : api.editInsertBookInfo ,
      method: 'post',
      headers:{"responseType": 'JSON'},
      data:params,
   })
}

// 获取房态日历(单日)
export function getRoomCalendarOneDay(data){
   return request({
      url:api.getRoomCalendarOneDay,
      method:'post',
      header:{'responseType':'JSON'},
      data
   })
}

// 房型排序
export function setRoomTypeSort(data){
   return request({
      url:api.setRoomTypeSort,
      method:'post',
      header:{'responseType':'JSON'},
      data
   })
}

// 房间号排序
export function setRoomSort(data){
   return request({
      url:api.setRoomSort,
      method:'post',
      header:{'responseType':'JSON'},
      data
   })
}

// 变更房态状态
export function changPmsRoomState (params) {
   return request({
      url: api.changPmsRoomState,
      method: 'post',
      headers:{"responseType": 'JSON'},
      data:params,
   })
}

// 批量 添加关房
export function addCloseRooms(data){
   return request({
      url:api.addCloseRooms,
      method:'post',
      header:{"responseType":"JSON"},
      data
   })
}

// 批量 删除关房
export function delCloseRoomsIds(data){
   return request({
      url:api.delCloseRooms + data,
      method:'get',
   })
}

// 通过房间ID和日期批量关房
export function delCloseRooms(data){
   return request({
      url:'pms/roomstate/deleteRooms',
      method:'post',
      header:{"resoiseType":"JSON"},
      data
   })
}

// 获取房态列表
export function getRoomStateList(data){
   return request({
      url:'/pms/roomStatus/getRoomStatusStatOneDay',
      method:'post',
      header:{'resoniseType':'JSON'},
      data
   })
}

// 根据订单id查询预订单详情
export function getReserveDetail(id){
   return request({
      url: api.getReserveDetail + id,
      method:'get'
   })
}

// 根据房型id查找价格 (全日/钟点）
export function getRoomPriceList(data){
   return request({
      url: api.getRoomPriceList,
      method:'post',
      data
   })
}

// 根据手机号查询会员信息
export function getLevelAndUnit(params){
   return request({
      url: api.getLevelAndUnit,
      method:'post',
      params:{  ...params },
   })
}

// 会员搜索
export function getMemberByKeyword(params){
   return request({
      url:api.getMemberByKeyword,
      method:'get',
      params
   })
}

// 协议搜索
export function getAgreementUnitByName(params){
   return request({
      url:api.getAgreementUnitByName,
      method:'get',
      params
   })
}
