<!-- 订单详情 -->
<template>
   <div>
      <div class="orderDetails"  :style="{right:`${ orderDetailsBools ? '0' : '-36%' }`}" v-if="orderDetailsBools">
         <!-- header -->
         <div class="orderDetails-header border-b">
            <el-tabs v-model="activeName" @tab-click="handleClick">
               <el-tab-pane v-for="item in timeroonList" :key="item.value" :label="item.label" :name="item.value"></el-tab-pane>
            </el-tabs>
            <span class="cursor-p m-right-10" @click="closeDrawer">X</span>
         </div>
         <!-- main -->
         <div class="orderDetails-main" v-if="activeName === 'details'">
            <el-scrollbar class="atrial-scrollbar" style="height: 100%">
               <div style="padding: 0 20px;">
                  <!-- 用户信息 -->
                  <div class="userInfo m-top-20">
                     <div>
                        <div class="userInfo-item">{{ userInfo.realName}} {{ userInfo.mobile }}</div>
                        <el-tag class="m-top-10" :type="ordertagStateType" >
                           {{ orderState | filterOrderState(that) }}
                        </el-tag>
                     </div>
                     <div class="channel-tag"><span></span>{{ userInfo.channel | filterChannel(that) }}</div>
                  </div>
                  <!-- 金额 -->
                  <ul class="amountBox border-b-dashed">
                     <li> <div class="amountBox-title">订单金额</div><span>￥</span>{{ userInfo.orderTotalPrice }}</li>
                     <li> <div class="amountBox-title">已付金额</div><span>￥</span>{{ userInfo.actualAmount }}</li>
                     <li> <div class="amountBox-title">还需付款</div><span>￥</span>{{userInfo.debtAmount}}</li>
                     <!-- 是否欠款 userInfo.amountsPayable -->
                  </ul>
                  <!-- 房间信息 -->
                  <div class="roomsInfo border-b-dashed">
                     <el-row class="m-top-20 m-bottom-20">
                        <div class="float-l"><h4>房间信息: ￥{{checkinRoomTotalPrice}}</h4></div>
                        <div v-if="checkInType === 'HOUR_ROOM'" class="float-r m-left-10" style="border: 1px solid greenyellow;color: green; font-size: 12px;padding:2px 5px;">钟点房</div>
                        <div class="float-r"> 共 {{checkinRoomDTOList.length}}间房 </div>
                     </el-row>
                     <ul class="roomsInfo-item" v-for="(item,index) in checkinRoomDTOList" :key="index" :style="{backgroundColor:`${ currentId === item.id ? '#ecf5ff' : 'white' }`}">
                        <li class="roomsInfo-item-s">
                           <div>{{item.roomTypeName}}-{{item.roomNo ?  item.roomNo : '未排房'}}</div>
                           <div class="m-top-10">{{item.startTime.substring(0,11)}}-{{item.endTime.substring(0,11)}}，{{item.durationText}}</div>
                        </li>
                        <li class="m-auto cursor-p" @click="checkinAdd(item)">
                           入住人<span style="background-color: #0c7ffd;padding:0 6px;border-radius: 10px;color: white;margin-left: 3px;">{{item.checkinMemberList.length}}</span>
                        </li>
                        <li class="m-auto">
                           <span>￥{{item.roomRate}}</span>
                           <el-tag class="m-left-10" size="mini"
                                   :type=" item.checkInState === 'NOT_CHECKIN' ? 'warning' : item.checkInState === 'CHECKOUT' ? 'info' : item.checkInState === 'CANCEL' ? 'info' : '' ">
                              <!-- NOT_CHECKIN 已预订 warning || CHECKOUT 已退房 info || CANCEL 已取消 info || CHECKIN 已入住 '' -->
                              {{ item.checkInState | filterCheckInState }}
                           </el-tag>
                        </li>
                       <el-button @click="handleBusinessCard(item)" type="text" v-if="item.checkInState === 'CHECKIN'">制卡</el-button>
                     </ul>
                  </div>
                  <!-- 其他消费 -->
                  <div class="consumeInfo border-b-dashed">
                     <el-collapse class="consumeInfo-collapse" v-model="consumeActiveName" >
                        <el-collapse-item name="1">
                           <template slot="title">
                             <div class="collapse-title">
                                <span>其他消费： {{consumptionTotalPrice}}</span>
                                <i class="hover-primary header-icon el-icon-plus" style="margin: auto 0;" @click.stop="consumeAdd"> 添加消费</i>
                             </div>
                           </template>
                           <!-- table -->
                           <el-table border fit :data="consumptionDTOList" highlight-current-row style="width: 100%"  max-height="260">
                              <el-table-column label="消费项目" prop="consumptionItemName" align="center" >
                                 <template v-slot="scope">
                                    {{ Number(scope.row.consumptionItemName) ? anyMaps.get(scope.row.consumptionItemName) : scope.row.consumptionItemName}}
                                 </template>
                              </el-table-column>
                              <el-table-column label="消费金额" prop="amount" align="center" > </el-table-column>
                              <el-table-column label="消费日期" prop="consumptionTime" align="center" >
                                 <template slot-scope="scope">
                                    {{scope.row.consumptionTime.substring(0,11)}}
                                 </template>
                              </el-table-column>
                              <el-table-column label="录入人" prop="refUserName" align="center" > </el-table-column>
                              <el-table-column label="备注" prop="remark" align="center" > </el-table-column>
                              <el-table-column label="操作" align="center" >
                                 <template slot-scope="scope">
                                    <el-button type="text" icon="el-icon-delete" @click="consumeDel(scope.row)"></el-button>
                                 </template>
                              </el-table-column>
                           </el-table>
                        </el-collapse-item>
                     </el-collapse>
                  </div>
                  <!-- 收款金额 collectionInfo -->
                  <div class="consumeInfo border-b-dashed">
                     <el-collapse class="consumeInfo-collapse" v-model="collectionActiveName">
                        <el-collapse-item name="1" >
                           <template slot="title">
                              <div class="collapse-title">
                                 <span>收款金额： {{ordertagState != 'Cancel' ? userInfo.actualAmount : 0}}</span>
                                 <i class="hover-primary header-icon el-icon-plus" style="margin: auto 0;" @click.stop="collectionAdd"> 收款/退款</i>
                              </div>
                           </template>
                           <!-- table -->
                           <el-table border fit :data="collectionRefundDTOList" highlight-current-row style="width: 100%" max-height="260">
                              <el-table-column label="项目" prop="itemType" width="100" align="center" >
                                 <template slot-scope="scope">
                                    {{ scope.row.itemType | filterIncome(that) }}
                                 </template>
                              </el-table-column>
                              <el-table-column label="支付方式" width="100" align="center" >
                                 <template slot-scope="scope">
                                    {{ scope.row.paymentMethod | filterPaymentMethod(that) }}
                                 </template>
                              </el-table-column>
                              <el-table-column label="金额" prop="amount" width="100" align="center" ></el-table-column>
                              <el-table-column label="日期" width="120" align="center">
                                 <template slot-scope="scope">
                                    {{ scope.row.payTime.substr(0,10)}}
                                 </template>
                              </el-table-column>
                              <el-table-column label="备注" prop="remark" width="170" align="center" ></el-table-column>
                              <el-table-column label="操作" prop="name" fixed="right" width="100" align="center" >
                                 <template slot-scope="scope">
                                    <el-button type="text" icon="el-icon-delete" @click="collectionDel(scope.row.id)"></el-button>
                                    <!-- slot-scope="scope" @click="collectionEdit(scope.row)"   @click="collectionDel(scope.row.id)"  -->
                                 </template>
                              </el-table-column>
                           </el-table>
                        </el-collapse-item>
                     </el-collapse>
                  </div>
                  <!-- 订单提醒 warnInfo -->
                  <div class="consumeInfo border-b-dashed">
                     <el-collapse class="consumeInfo-collapse" v-model="warnActiveName" >
                        <el-collapse-item name="1">
                           <template slot="title">
                              <div class="collapse-title">
                                 <span>订单提醒： {{orderReminderList.length}}</span>
                                 <i class="hover-primary header-icon el-icon-plus" style="margin: auto 0;" @click.stop="warnAdd"> 添加提醒</i>
                              </div>
                           </template>
                           <!-- table -->
                           <div class="m-bottom-10" v-for="item in orderReminderList" :key="item.id" >
                              <div class="warn-item">
                                 <span class="m-left-10">{{ item.reminderTime}}</span>
                                 <span class="m-left-10">{{ item.reminderContent}}</span>
                              </div>
                              <div style="display: inline-block;">
                                 <el-button class="m-left-10" type="text" icon="el-icon-edit" @click="warnEdit(item)"></el-button>
                                 <el-button class="hover-delete " type="text" icon="el-icon-delete" @click="warnDel(item)"></el-button>
                                 <!--
                                 @click="warnEdit(item,index)"
                                 @click="warnDel(item,index)"
                                 -->
                              </div>
                           </div>
                        </el-collapse-item>
                     </el-collapse>
                  </div>
                  <!-- 备注 -->
                  <div style="height: 145px;font-family: Microsoft YaHei;font-size: 14px">
                     <div class="m-top-10 m-bottom-10">备注：{{ userInfo.remark ? userInfo.remark : '无' }}</div>
                     <div class="m-top-10 m-bottom-10">订单号：{{bookIds}}</div>
                  </div>
               </div>
            </el-scrollbar>
         </div>

         <!-- footer -->
         <div class="orderDetails-footer" style="display: flex;justify-content: space-between;" v-if="activeName === 'details'">
            <el-dropdown class="orderDetails-footer-item" @command="dropdownCommand" trigger="click">
              <span class="el-dropdown-link">
                更多操作<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
               <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="Check_in_out.includes('NOT_CHECKIN')" command="handlecancelReserve">取消预定</el-dropdown-item>
                  <el-dropdown-item v-if="Check_in_out.includes('CANCEL')" command="handlerecoverReserve">恢复预定</el-dropdown-item>
                  <el-dropdown-item v-if="Check_in_out.includes('CHECKIN')" command="handlecancelCheckIn">撤销入住</el-dropdown-item>
                  <el-dropdown-item v-if="Check_in_out.includes('CHECKOUT')" command="handleCancelCheckout">撤销退房</el-dropdown-item>
                  <el-dropdown-item v-if="ordertagState == 'Cancel'" command="handleDeleteOrder">删除订单</el-dropdown-item>
               </el-dropdown-menu>
            </el-dropdown>
            <div style="margin-right: 10px;">
              <el-button style="margin:auto 10px auto 0" @click="handlePrint" v-if="!abnormalOrders">打印</el-button>
              <el-button v-if="Check_in_out.includes('noEdit')" class="bg-gradient" type="primary" style="margin:auto 10px auto 0" @click="handleEditBut">修改订单</el-button>
              <el-button v-if="Check_in_out.includes('in')" class="bg-gradient" type="primary" style="margin:auto 10px auto 0" @click="handleInOut('in')">办理入住</el-button>
              <el-button v-if="Check_in_out.includes('CHECKIN')" class="bg-gradient" type="primary" style="margin:auto 10px auto 0" @click="handleInOut('out')">办理退房</el-button>
            </div>
         </div>

         <!-- 操作日志 -->
         <div v-if="activeName === 'operationLog'" class="log_box">
            <el-scrollbar class="atrial-scrollbar" style="height: 100%">
               <div class="operateLog" v-for="(item,index) in operateLogList" :key="index">
                  <div class="operateLog_tail"> </div>
                  <div class="log_content">
                     <div class="log_title">
                        <span class="m-right-10">{{item.operateTime}}</span>
                        <span class="m-right-10">操作人:{{item.operator}}</span>
                        <span class="m-right-10">{{item.actionType | filterLogType}}</span>
                     </div>
                     <div class="log">
                        <div v-for="(items,index) in logContent(item.actionContent)" :key="items+index">
                           {{items}}
                        </div>
                        <template v-if="item.remark"> 备注：{{item.remark}}</template>
                     </div>
                  </div>
               </div>
            </el-scrollbar>
         </div>
      </div>

      <!-- 入住人 抽屉 -->
      <drawer-checkin :checkInpersonBool="checkInpersonBool" :currentCheck="currentCheckinRoom" :checkinList="checkinList"
                      :state="`SingleEdit`" @closeCheckin="checkinClose" @handleFinish="handleFinish"></drawer-checkin>
      <!-- 添加消费 抽屉 -->
      <Drawer :bool="consumeBool" :headerTitle="`添加消费`" @close="consumeClose">
         <template v-slot:drawerMain>
            <el-form ref="consumeRuleForm" :model="consumeRuleForm" :rules="consumeRules" label-position="right" label-width="100px" style="margin: 20px 20px 0 20px;">
               <el-form-item label="消费项名称" prop="consumptionItemName">
                  <el-select v-model="consumeRuleForm.consumptionItemName" placeholder="请选择" @change="consumeChange">
                     <el-option v-for="item in consumeOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
                  </el-select>
               </el-form-item>
               <el-form-item label="数量" prop="quantity">
                  <el-input v-model.number="consumeRuleForm.quantity" placeholder="数量" @change="consumeChange" class="width-220" :onkeyup='dataValidation' clearable></el-input>
               </el-form-item>
               <el-form-item label="金额" prop="amount">
                  <el-input v-model="consumeRuleForm.amount" placeholder="金额" class="width-220" :onkeyup='dataValidation' clearable>
                     <span slot="prefix" class="RMB">￥</span>
                  </el-input>
               </el-form-item>
               <el-form-item label="消费时间"  prop="consumptionTime">
                  <el-date-picker v-model="consumeRuleForm.consumptionTime" type="date" value-format="yyyy-MM-dd" placeholder="消费日期"></el-date-picker>
               </el-form-item>
               <el-form-item label="备注">
                  <el-input type="textarea" v-model="consumeRuleForm.remark" maxlength="200" rows="4" show-word-limit clearable  placeholder="请输入备注"></el-input>
               </el-form-item>
            </el-form>
            <!-- footer -->
            <div class="drawer-footer t-right">
               <el-button @click="consumeClose(false)">取消</el-button>
               <el-button class="bg-gradient" type="primary" style="margin:0px 20px" @click="consumeFinish('consumeRuleForm')">确认</el-button>
            </div>
         </template>
      </Drawer>

      <!-- 提醒信息 抽屉 -->
      <Drawer :bool="warnBool" :headerTitle="`${ warnState === 'add' ? '添加' : '编辑' }提醒`" @close="warnClose" >
         <template v-slot:drawerMain>
            <el-form ref="warnRuleForm" :model="warnRuleForm" :rules="warnRules" label-position="right" label-width="100px" style="margin: 20px 20px 0 20px;">
               <el-form-item label="提醒时间" prop="datetime">
                  <el-date-picker v-model="warnRuleForm.reminderTime" type="datetime"  value-format="yyyy-MM-dd HH:mm:ss" :picker-options="warnpickerOptions"  placeholder="选择日期时间"></el-date-picker>
               </el-form-item>
               <el-form-item label="提醒内容" prop="remark">
                  <el-input type="textarea" class="width-300" v-model="warnRuleForm.reminderContent" maxlength="200"  rows="4" show-word-limit clearable  placeholder="请输入提醒内容"></el-input>
               </el-form-item>
            </el-form>
            <!-- footer -->
            <div class="drawer-footer t-right">
               <el-button @click="warnClose(false)">取消</el-button>
               <el-button class="bg-gradient" type="primary" style="margin:0px 20px" @click="warnFinish('warnRuleForm')">完成</el-button>
            </div>
         </template>
      </Drawer>

      <!-- 取消预定/办理入住/办理退房/撤销退房 -->
      <Drawer :bool="checkInBool" @close="handleInclose"
              :headerTitle="`${ checkInState === 'in' ? '办理入住' : checkInState === 'cancelIn' ? '撤销入住' : checkInState === 'out' ? '办理退房' : checkInState === 'cancelOut' ? '撤销退房' : checkInState === 'cancelReserve' ? '取消预定' : checkInState === 'recoverReserve' ? '恢复预定' : '' }`">
         <template v-slot:drawerMain>
            <div style="margin: 20px 20px 0 20px;">
              <div style="display: flex;justify-content: space-between;margin-bottom: 20px;">
                  <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange" >全选</el-checkbox>
                  <div style="font-size: 14px;color: gray">共{{checkinRoomDTOLists.length}}间  已选{{checkedCheckInOrder.length}}间</div>
               </div>
               <el-checkbox-group v-model="checkedCheckInOrder" class="CheckInOrderBox">
                  <el-checkbox v-for="(item,index) in checkinRoomDTOLists" :label="item" :key="index">
                     <el-row class="CheckInOrderBox-item">
                        <div style="margin: auto auto auto 0;">
                           <!-- 未排房 -->
                           <div v-if="checkInState === 'in' && !item.hasOwnProperty('roomNo')">
                              <el-cascader class="width-150" slot="prepend" v-model="item.rooms" :options="roomOptions" style="line-height: 37px"
                                          @visible-change="(bool)=>{ visibleChange(bool,item) }"></el-cascader>
<!--                                @change="changeCombin(item)" -->
                           </div>
                           <div v-else>{{item.roomTypeName}}/{{item.roomNo}}</div>
                        </div>
                        <div style="line-height: 20px;margin-right: 10px;font-weight: bold;">
                           <div class="m-top-10">{{item.startTime.substring(0,11)}} 至 {{item.endTime.substring(0,11)}}，共 {{item.durationText}} </div>
                           <div class="m-bottom-10">房费：¥{{item.roomRate}}</div>
                           <div class="m-bottom-10" v-if="checkInState === 'cancelReserve'">违约金
                              <input class="width-100 modInput" size="mini" v-model="item.liquidatedDamages" @change="liquidatedDamagesChange"/>
                           </div>
                        </div>
                     </el-row>
                  </el-checkbox>
               </el-checkbox-group>
               <el-divider></el-divider>
               <el-input type="textarea" v-model="CheckInOrderRemark" maxlength="200"  rows="4" show-word-limit clearable
                         :placeholder="`请填写${checkInState === 'in' ? '办理入住' : checkInState === 'cancelIn' ? '撤销入住' : checkInState === 'out' ? '办理退房' : checkInState === 'cancelOut' ? '撤销退房' : checkInState === 'cancelReserve' ? '取消预定' : checkInState === 'recoverReserve' ? '恢复预定' : '' }备注信息`">
               </el-input>
               <div class="m-top-20" v-if="checkInState === 'cancelReserve'">
                  <div>订单总额 ￥{{ orderTotalPrice }}</div>
                  <div style="display: flex">
                     <div>已付金额 ￥{{ userInfo.actualAmount }}</div>
                     <div style="color: red;margin-left: auto;">还需还款 ￥{{ orderTotalPrice - userInfo.actualAmount }}</div>
                  </div>
               </div>
            </div>
            <!-- footer -->
            <div class="drawer-footer t-right">
<!--               <el-button @click="handleInEdit">编辑</el-button>-->
               <el-button class="bg-gradient" type="primary" style="margin:0px 20px" @click="handleInFinish">
                  {{ checkInState === 'in' ? '办理入住' : checkInState === 'cancelIn' ? '撤销入住' : checkInState === 'out' ? '办理退房' : checkInState === 'cancelOut' ? '撤销退房' : checkInState === 'cancelReserve' ? '取消预定' : checkInState === 'recoverReserve' ? '恢复预定' : ''  }}
               </el-button>
            </div>
         </template>
      </Drawer>
      <!-- 修改预订单 抽屉 -->
      <add-atrialstate :drawerBool="drawerBool" :bookId="bookIds" @closeDrawer="atrialstateClose" @Refresh="getDetail('edit')" v-on="$listeners"></add-atrialstate>
     <!-- 快速制卡 抽屉 -->
     <business-card ref="businessCard" :visible="visibleCard" @close="closeCardDrawer"/>
     <!-- 打印抽屉 -->
     <print-sheet ref="print" :visible="visiblePrint" @close="closePrintSheet"/>
      <!-- 收款 抽屉 -->
     <el-drawer class="drawer" :visible.sync="incomeShow" direction="rtl" :wrapperClosable="false" :before-close="incomeClose" :size="532">
         <div slot="title">
            <el-page-header @back="incomeClose">
              <div slot="content">
               <el-tabs v-model="incomeName">
                  <el-tab-pane label="收款" name="proceeds"></el-tab-pane>
                  <el-tab-pane label="退款" name="refund"></el-tab-pane>
               </el-tabs>
              </div>
            </el-page-header>
         </div>
         <div style="padding: 12px 24px;margin-bottom:40px;">
            <ul class="amountBox border-b-dashed">
               <li> <div class="amountBox-title">订单金额</div><span>￥</span>{{ userInfo.orderTotalPrice }}</li>
               <li> <div class="amountBox-title">已付金额</div><span>￥</span>{{ userInfo.actualAmount }}</li>
               <li> <div class="amountBox-title">还需付款</div><span>￥</span>{{ userInfo.debtAmount }}</li>
            </ul>
            <div v-if="incomeName==='proceeds'">
               <el-form :model="form" ref="form" :rules="rules" label-width="80px">
                  <el-form-item label="项目">
                     <el-radio-group v-model="form.resource">
                        <el-radio :label="0">收款</el-radio>
                        <el-radio :label="1">收押金</el-radio>
                     </el-radio-group>
                  </el-form-item>
                  <el-form-item label="支付方式" prop="paymentMethod">
                     <el-select v-model="form.paymentMethod" placeholder="请选择" class="width-220">
                        <el-option v-for="items in modePaymentList" :key="items.id" :label="items.name" :value="items.name"></el-option>
                     </el-select>
                  </el-form-item>
                  <el-form-item label="金额" prop="amount">
                     <el-input v-model="form.amount" placeholder="金额" class="width-220" :onkeyup='dataValidation' clearable>
                        <span slot="prefix" class="RMB">￥</span>
                     </el-input>
                  </el-form-item>
                  <el-form-item label="日期" prop="time">
                     <el-date-picker v-model="form.time" type="date" value-format="yyyy-MM-dd" placeholder="消费日期" :picker-options="pickerOptions"></el-date-picker>
                  </el-form-item>
                  <el-form-item label="备注">
                     <el-input type="textarea" :rows="3" maxlength="200" show-word-limit placeholder="备注信息" v-model="form.remark"></el-input>
                  </el-form-item>
               </el-form>
            </div>
            <div v-if="incomeName==='refund'">
               <div class="refund">
                  <el-radio v-model="refundType" label="1" border disabled>线上原路退款</el-radio>
                  <el-radio v-model="refundType" label="2" border>线下退款</el-radio>
               </div>
               <div v-show="refundType==='1'">
                  <div style="margin-bottom: 16px;color: #606266;">请选择需要退款的流水</div>
                  <!-- table -->
                  <el-table border fit highlight-current-row style="width: 100%" max-height="260">
                     <el-table-column label="发生日期" width="100"></el-table-column>
                     <el-table-column label="收款金额"></el-table-column>
                     <el-table-column label="备注"></el-table-column>
                     <el-table-column label="可退金额"></el-table-column>
                  </el-table>
               </div>
               <div v-show="refundType==='2'">
                  <el-form :model="form" ref="form" :rules="rules" label-width="80px">
                     <el-form-item label="项目">
                        <el-radio-group v-model="form.resource">
                           <el-radio :label="0">退款</el-radio>
                           <el-radio :label="1">退押金</el-radio>
                        </el-radio-group>
                     </el-form-item>
                     <el-form-item label="支付方式" prop="paymentMethod">
                        <el-select v-model="form.paymentMethod" placeholder="请选择" class="width-220">
                           <el-option v-for="items in modePaymentList" :key="items.id" :label="items.name" :value="items.name"></el-option>
                        </el-select>
                     </el-form-item>
                     <el-form-item label="金额" prop="amount">
                        <el-input v-model="form.amount" placeholder="金额" class="width-220" :onkeyup='dataValidation' clearable >
                           <span slot="prefix" class="RMB">￥</span>
                        </el-input>
                     </el-form-item>
                     <el-form-item label="日期" prop="time">
                        <el-date-picker v-model="form.time" type="date" value-format="yyyy-MM-dd" placeholder="消费日期" :picker-options="pickerOptions"></el-date-picker>
                     </el-form-item>
                     <el-form-item label="备注">
                        <el-input type="textarea" :rows="3" maxlength="200" show-word-limit placeholder="备注信息" v-model="form.remark"></el-input>
                     </el-form-item>
                  </el-form>
               </div>
            </div>
         </div>
         <div class="foot" style="background: white">
            <el-button @click="incomeClose">取消</el-button>
            <el-button class="bg-gradient" type="primary" style="margin-left:16px" @click="submitForm('form')">确定</el-button>
         </div>
      </el-drawer>
   </div>
</template>

<script>
import {mapState} from "vuex";
import PrintSheet from './Print/PrintSheet'
import AddAtrialstate from "./AddAtrialstate";
import Drawer from "@/components/global/drawer";
import { getReserveDetail } from "@/api/pms/roomCalendar";
import { getRoomPriceList } from '@/api/pms/roomCalendar'
import { getEnabledList } from '@/api/pms/collectionSetting/collectionSetting'
import { dateFactory,getDict , accuracyCalculation } from '@/common/js/common'
import BusinessCard from "@/components/local/atrialState/BusinessCard";
import { getListConsumptionItem,setIncome,removeIncomelist } from "@/api/pms/accommodationSetting/consumerItemSet";
import { getOperateLog , consumptionDel , consumptionAdd, reminderAddEdit , reminderDel , handlecheckInOut, removeBookOrder } from "@/api/pms/orderDetails"

export default {
   name: "orderDetails",
   components: { Drawer , AddAtrialstate, BusinessCard, PrintSheet },
   props:{
      // 是否显示
      orderDetailsBool:{
         type:Boolean,
         default:false,
      },
      bookId:{
         type:String,
         default:'',
      },
      checkinId: {
          type:String,
          default:'',
      },
      currentId:{
         type:String,
         default:'',
      },
      // 判断是否从异常订单页面来
      abnormalOrders:{
         type:Boolean,
         default:false,
      }
   },
   watch:{
      orderDetailsBool(){
         this.orderDetailsBools = this.orderDetailsBool
         if(this.orderDetailsBool) {
            this.getListConsumptionItems()
            this.getPayList()
         }
      },
      bookId(){
         this.bookIds = this.bookId
         if(this.bookId) {
            this.getDetail()
            this.getLog()
         }
      },
      checkedCheckInOrder(){
         this.liquidatedDamagesChange()
      },
      incomeName(){
         this.form.resource = 0;
         this.form.amount = '';
         this.form.remark = '';
      }
   },
   data(){
      return {
         that:this,
         anyMaps:new Map(),
        visiblePrint: false,

        visibleCard: false,   // 制卡抽屉是否显示

         orderDetailsBools:false,    // 是否显示
         timeroonList:[
            {value:'details',label:'订单详情'},
            {value:'operationLog',label:'操作日志'}
         ],
         activeName:'details',      // 订单详情
         bookIds:'',                // 订单id
         ordertagState:'',          // 订单状态
         ordertagStateType:'',      // 订单状态
         operateLogList:[],         // 操作日志

         // 用户/金额信息
         userInfo:{
            realName:'',            // 姓名
            mobile:'',              // 电话
            channel:'',             // 渠道

            orderTotalPrice:0,      // 订单金额
            actualAmount:0,         // 已付金额
            amountsPayable:0,       // 还需付款

            remark:'',              // 备注
         },

         checkInType:"",            // HOUR_ROOM 钟点房;FULL_TIME 全日房
         // 房间信息
         checkinRoomDTOList:[],
         checkinRoomTotalPrice:0,   // 房间总价

         // 入住人抽屉
         checkinList:[{
            realName:'',
            mobile:'',
            certificateType:'',  // 证件类型
            certificateNo:'',    // 证件号
         }],
         checkInpersonBool:false,         // 是否显示 入住人抽屉
         currentCheckinRoom:{},     // 当前入住人房间信息

         // 其他消费
         consumeActiveName:[''],
         consumptionDTOList:[],
         consumptionTotalPrice:0,   // 消费总价
         consumeOptions:[],         // 消费项 下拉数据
         consumeBool:false,         // 是否显示 添加消费抽屉
         consumeRuleForm:{
            consumptionItemName:'', // 消费项名称
            quantity:1,             // 数量
            amount:0,               // 金额
            consumptionTime:dateFactory.dateFormat(false),     // 消费时间
            remark:'',              // 备注
         },
         consumeRules:{
            consumptionItemName: [{ required: true, trigger: "blur", message: "请输入消费项名称" }],
            quantity: [{ required: true, trigger: "blur", message: "请输入数量" }],
            consumptionTime: [{ required: true, trigger: "change", message: "请选择消费时间" }],
            amount: [{ required: true, trigger: "blur", message: "请输入金额" }],
         },

         // 收款金额
         collectionActiveName:[''],
         collectionRefundDTOList:[],
         // 支付方式列表
         modePaymentList:[],

         // 订单提醒
         warnActiveName:[''],
         orderReminderList:[],
         warnBool:false,            // 是否显示 订单提醒消费抽屉
         warnpickerOptions: { disabledDate(time) {  return time.getTime() < Date.now() - 24 * 60 * 60 * 1000; }},
         // warndefaultTime:'2022-02-23 12:00:00', // 默认此时时间
         warnState:'add',           // 新增/编辑
         warnId:'',                 // 编辑id
         warnRuleForm:{
            reminderTime:'',        // 提醒时间
            reminderContent:''      // 提醒内容
         },
         warnRules:{
            reminderTime: [{ required: true, trigger: "change", message: "请选择日期" }],
            reminderContent: [{ required: true, trigger: "blur", message: "请输入提醒内容" }],
         },

         Check_in_out:[],           // 是否有 入住订单状态

         // 抽屉  办理入住/退房 撤销入住/退房/预定
         checkInBool:false,         // 是否显示
         checkInState:'',           // 办理
         checkAll:false,            // 是否全选
         isIndeterminate:true,
         checkinRoomDTOLists:[],    // 可办理的过滤数据集合
         checkedCheckInOrder:[],    // 选中值
         CheckInOrderRemark:'',     // 备注

         roomOptions:[],

         // 预定 订单总价
         orderTotalPrice:0,

         // 修改预订单
         drawerBool:false,          // 是否显示

        // 收款抽屉
        incomeShow:false,          // 是否打开抽屉
        incomeName:'proceeds',     // 抽屉标题
        form: {                    // 收款表单
          resource:0,              // 收款/收押金
          amount:'',               // 金额
          time: dateFactory.dateFormat(false),        // 日期
          paymentMethod: '',       // 支付方式
          remark: ''               // 备注
        },
        rules:{                    //校验规则
         paymentMethod:[{ required:true, message: '请选择方式' , trigger: 'change'}],
         amount:[{ required:true,message: '请输入金额',trigger: 'blur'},],
         time:[{ required:true, message: '请选择日期' , trigger: 'change'}],
        },
        refundType:'2',            // 退款类型

         // 日历快捷选项配置
         pickerOptions: {
            disabledDate(time) {
               return time.getTime() >= Date.now() ;
            }
         },
         orderState: ''
      }
   },
   computed:{
      ...mapState(['dictData','hotelInfo']),
      logContent(){
         return (str)=>{
           str = str.replace(/null/g, "未排房");
         //   str = str.replace(/00:00:00/g, "12:00:00");
           let arr = str.split("@");
           return arr
         }
      },
      // 验证输入金额
      dataValidation(){
         return 'value=value.replace(/[^\\d.]/g,"").replace(".", "$#$").replace(/\\./g, "").replace("$#$", ".").replace(/^(\\-)*(\\d+)\\.(\\d\\d).*$/, "$1$2.$3").replace(/^\\./g, "").replace(/\\.{2,}/g, ".")';
      },
   },
   mounted(){
      getDict(['order-source','back-money-type','income'],false);
   },
   methods:{
     // 获取 支付方式列表
      getPayList(){
         getEnabledList({ hotelId: this.hotelInfo.id }).then(({ records }) => {
            this.modePaymentList = records
         })
      },
     // 关闭制卡抽屉
     closeCardDrawer(bool) {
       this.visibleCard = bool
     },
     // 处理制卡
     handleBusinessCard(row) {
       if (!row.checkinMemberList || row.checkinMemberList.length === 0) {
         return this.$message.error('请选择入住人！')
       }
       this.visibleCard = true
       this.$refs.businessCard.getRoomInfo(row)
       this.$refs.businessCard.getByBrandCode()
     },
     // 关闭打印抽屉
     closePrintSheet(bool) {
       this.visiblePrint = bool
     },
     // 处理打印
     handlePrint() {
       this.visiblePrint = true
       this.consumptionDTOList.forEach(item => {
         item.consumerName = this.anyMaps.get(item.consumptionItemName)
       })
       const content = {
         checkinRoomDTOList: this.checkinRoomDTOList,
         consumptionDTOList: this.consumptionDTOList,
         collectionRefundDTOList: this.collectionRefundDTOList,
         userInfo: this.userInfo,
       }
       this.$refs.print.getPrintContent(content)
     },

      // 监听 违约金变动   取消预定时的订单总价 这里还需要考虑到除了取消预定之前的其他状态订单 this.checkinRoomDTOList  userInfo.orderTotalPrice
      liquidatedDamagesChange(){
         let orderTotalPrice = 0
         let totalPrice = 0
         this.checkinRoomDTOLists.forEach(item=>{
            orderTotalPrice = Number(item.liquidatedDamages) + orderTotalPrice
            totalPrice = Number(item.roomRate) + totalPrice
         })
         if(orderTotalPrice == 0 && this.checkedCheckInOrder.length === 0) this.orderTotalPrice = this.userInfo.orderTotalPrice - totalPrice
         else this.orderTotalPrice = orderTotalPrice
      },
      // 关闭抽屉 清空数据
      closeDrawer(){
         for (const k in this.userInfo) this.userInfo[k] = ''
         let arr = ['checkinRoomDTOList','consumptionDTOList','collectionRefundDTOList','orderReminderList']
         for (const k in arr) this[k] = []
         // 折叠面板为折叠状态
         let arrs = ['consumeActiveName','collectionActiveName','warnActiveName']
         for (const k in arrs) this[k] = ['']

         this.orderDetailsBools = false
         this.bookIds = ''
         this.$emit("closeOrderDetails",this.orderDetailsBools,this.bookIds)
      },

      // 获取消费项
      getListConsumptionItems(){
         getListConsumptionItem( {hotelId: this.hotelInfo.id,state:'ENABLED'}).then(res=>{
            if(res.success) {
               this.consumeOptions = res.records
               this.consumeOptions.forEach(item=>{ this.anyMaps.set(item.id,item.name) })
            }
         })
      },
      // 切换菜单
      handleClick(tab){
         if (tab.name == "operationLog") {
            this.getLog();
         }
      },
      // 获取订单操作日志
      getLog(){
         getOperateLog(this.bookIds).then(res=>{
            if(res.success){
               this.operateLogList = res.data;
            }
         })
      },

      // 获取订单详情
      getDetail(state){
         getReserveDetail(this.bookId).then(res=>{
            if(res.success){
               let resData = res.records
               // 用户/金额信息
               for (const k in this.userInfo) k in resData && (this.userInfo[k] = resData[k])
               this.userInfo.debtAmount = accuracyCalculation(Number(this.userInfo.orderTotalPrice),this.userInfo.actualAmount,'-')  // 解决精度丢失问题

               this.checkinRoomTotalPrice = 0         // 房间总价
               this.consumptionTotalPrice = 0         // 消费总价

               /* Confirmed  InProgress  Finished Cancel */
               // 房间信息
               this.Check_in_out = []
               let orderState = []
               resData.checkinRoomDTOList.forEach((item,index)=>{
                  if(item.checkInType === 'FULL_TIME') item.durationText = item.duration + '晚' // 天数
                  else {
                     // 24 * 3600 * 1000
                     let checkIngTime = dateFactory.getDistanceDays(item.startTime,item.endTime,true)
                     let minute = Math.ceil(checkIngTime%3600000/60000)
                     item.durationText = parseInt(checkIngTime/3600000)+'小时' + (minute ? minute + '分钟' : '' )
                  }

                  if(!item.hasOwnProperty('roomRate')) item.roomRate = 0
                  this.checkinRoomTotalPrice = (this.checkinRoomTotalPrice * 100 + item.roomRate * 100)/100
                  // 当为(未入住)时，需要等今天以及今天之前才能办理入住
                  if(item.checkInState === 'NOT_CHECKIN') {
                     let tiem = item.startTime.substring(0,11)           // 预订单开始日期
                     let nowTime = dateFactory.getDistanceToday(0,false) // 今日
                     if(dateFactory.compareTime(nowTime,tiem)) this.Check_in_out.push('in') // 办理入住
                  }
                  this.Check_in_out.push(item.checkInState)
                  if(item.checkInState !== 'CHECKOUT') this.Check_in_out.push('noEdit') // 如果全是退房，则不显示(修改订单按钮), 不全是则显示
                  orderState.push(item.checkInState)
                  if(index === 0) this.checkInType =  item.checkInType  // 钟点房
                  item.channel = resData.channel
                  let state = item.checkInState
                  if(state === 'CHECKIN') {this.orderState = 'InProgress'}
                  // 已确认
                  else if(state === 'NOT_CHECKIN') {this.orderState = 'Confirmed'}
                  // 已取消
                  else if(state === 'CANCEL')  {this.orderState = 'Cancel'}
                  // 已完成
                  else if(state === 'CHECKOUT') {this.orderState = 'Finished'}
               })
               this.checkinRoomDTOList = resData.checkinRoomDTOList

               let set = Array.from(new Set(orderState)) // 去重
               // 进行中
               if(set.length > 1){
                  if(!(set.includes('NOT_CHECKIN') && set.includes('CANCEL')) || !(set.includes('CHECKOUT') && set.includes('CANCEL'))) this.ordertagState = 'InProgress'
               } else if(set.length === 1 && set.includes('CHECKIN')) this.ordertagState = 'InProgress'
               // 已确认
               if((set.length === 1 && set.includes('NOT_CHECKIN')) || (set.length === 2 && set.includes('NOT_CHECKIN') && set.includes('CANCEL'))) this.ordertagState = 'Confirmed'
               // 已取消
               if(set.length === 1 && set.includes('CANCEL'))  this.ordertagState = 'Cancel'
               // 已完成
               if((set.length === 1 && set.includes('CHECKOUT')) || (set.length === 2 && set.includes('CHECKOUT') && set.includes('CANCEL')) ) this.ordertagState = 'Finished'

               // 消费信息
               resData.consumptionDTOList.forEach(item=>{
                  this.consumptionTotalPrice = (this.consumptionTotalPrice * 100 + item.amount * 100) /100
               })
               this.consumptionDTOList = resData.consumptionDTOList

               // 收款金额
               this.collectionRefundDTOList = resData.collectionRefundDTOList

               // 提醒
               this.orderReminderList = resData.orderReminderList

               // // 修改预订单
               // if(state === 'edit'){ console.log('-') }
            }
         })
      },

      // 新增 收款/退款
      collectionAdd(){
        this.incomeShow = true;
      },
      // 关闭 收款/退款 抽屉
      incomeClose(){
         this.incomeShow = false ;
         this.incomeName = 'proceeds'
         for (const k in this.form) this.form[k] = ''
         this.form.resource = 0; // 收款/收押金
         this.form.paymentMethod= ''
         this.form.time = dateFactory.dateFormat(false)

      },
      // 提交
      submitForm(formName){
         this.$refs[formName].validate((valid) => {
            if (valid){
               let str = "";
               if (this.incomeName==="proceeds") { // 收款
                  str = ['PROCEEDS','DEPOSIT_RECEIVE'][this.form.resource];
               } else {
                  str = ['REFUND','DEPOSIT_REFUND'][this.form.resource];
               }
               let beginTime = dateFactory.getDistanceToday(0,true);
               let m = " "+beginTime.split(' ')[1];
               let data ={
                  bookId:this.bookIds,                  // 订单ID
                  hotelId:this.hotelInfo.id,            // 酒店ID
                  itemType:str,                         // 项目类型   PROCEEDS(1, "收款"),, DEPOSIT_RECEIVE(2, "收押金"),, REFUND(3, "退款"),, DEPOSIT_REFUND(4, "退押金");
                  paymentMethod:this.form.paymentMethod,// 支付方式
                  amount:this.form.amount*1,            // 金额
                  companyId:this.hotelInfo.storeId,     // 集团ID
                  payTime:this.form.time+m,             // 支付时间
                  remark:this.form.remark               // 备注
               }
               setIncome(data,this.abnormalOrders).then(res=>{
                  if(res.success) {
                     this.incomeClose();
                     this.getDetail();
                     this.$emit('getRoomCalendars') // 刷新 房态日历视图
                  }
               })
            } else {
               console.log('error submit!!');
               return false;
            }
         });
      },
      // 删除 收款/退款
      collectionDel(row){
          this.$confirm('确定删除吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
         }).then(() => {
            removeIncomelist(row).then(({ success }) => {
               if (success) {
                  this.$message.success('删除成功!');
                  this.getDetail();
               }
            })
         })
      },

      // 添加 入住人抽屉
      checkinAdd(item){
         this.currentCheckinRoom = {
            startTime:item.checkInType === 'FULL_TIME' ? item.startTime.substring(0,9) : item.startTime,
            endTime:item.endTime.substring(0,9),
            duration:item.durationText,
            rooms:item.roomNo ,
            roomType:item.roomTypeName,
            price :item.roomRate,
            id:item.id,
            roomRate:item.roomRate,
            checkInType:item.checkInType
         }
         this.checkinList = item.checkinMemberList
         this.checkInpersonBool = true
      },
      // 关闭入住人 抽屉
      checkinClose(val){
         this.checkInpersonBool = val
         this.checkinList = []
      },
      // 完成入住人 抽屉
      handleFinish(bool){
         this.checkInpersonBool = bool
         this.getDetail()
      },

      // 删除消费
      consumeDel(row){
         this.$confirm('确定删除吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
         }).then(() => {
            consumptionDel(row.id).then(res=>{
               if(res.success){
                  this.getDetail()
                  this.$message.success( '删除成功!');
               }
            })
         })
      },
      // 新增消费
      consumeAdd(){
         this.consumeBool = true
      },
      // 关闭 消费抽屉 清空数据
      consumeClose(){
         this.consumeBool = false
         for (const k in this.consumeRuleForm) this.consumeRuleForm[k] = ''
         this.consumeRuleForm.consumptionTime = dateFactory.dateFormat(false)
      },
      // 消费项名称 发生变化时
      consumeChange(){
         this.consumeOptions.forEach(item=>{
            if(item.id === this.consumeRuleForm.consumptionItemName){
               this.consumeRuleForm.amount = item.price * this.consumeRuleForm.quantity
            }
         })
      },
      // 完成 消费抽屉
      consumeFinish(formName){
         this.$refs[formName].validate((valid) => {
            if (valid) {
               this.consumeOptions.forEach(item=>{
                  if(item.id === this.consumeRuleForm.consumptionItemName) this.consumeRuleForm.consumptionItemName = item.name
               })
               this.consumeRuleForm.consumptionTime = this.consumeRuleForm.consumptionTime + ' 00:00:00'

               let param = {
                  bookId:this.bookIds,
                  ...this.consumeRuleForm
               }
               consumptionAdd(param).then(res=>{
                  if(res.success){
                     this.getDetail()
                     this.consumeClose()
                     this.$message.success( '添加成功!');
                  }
               })
            }else{
               console.log('error submit!!');
               return false;
            }
         })
      },

      // 新增提醒
      warnAdd(){
         this.warnBool = true
         this.warnState = 'add'
      },
      // 编辑提醒  此处需要处理回显
      warnEdit(row){
         this.warnBool = true
         this.warnState = 'edit'
         this.warnId = row.id
         for (const k in this.warnRuleForm) k in row && (this.warnRuleForm[k] = row[k])
      },
      // 删除提醒
      warnDel(row){
         reminderDel(row.id).then(res=>{
            if(res.success){
               this.$message.success( '删除成功!');
               this.getDetail()
            }
         })
      },
      // 关闭提醒 抽屉
      warnClose(val){
         this.warnBool = val
         for (const k in this.warnRuleForm) this.warnRuleForm[k] = ''
      },
      // 完成提醒 抽屉
      warnFinish(formName){
         this.$refs[formName].validate((valid) => {
            if (valid) {
               let param = {
                  hotelId:this.hotelInfo.id,
                  bookId:this.bookIds,
                  ...this.warnRuleForm
               }
               if(this.warnState === 'edit') param.id = this.warnId
               reminderAddEdit(param,this.warnState).then(res=>{
                  if(res.success){
                     this.warnClose()
                     this.getDetail()
                     this.$message.success( `${this.warnState === 'add' ? '添加' : '编辑' }成功!`);
                  }
               })
            }else{
               console.log('error submit!!');
               return false;
            }
         })
      },

      // 修改订单
      handleEditBut(){
         this.drawerBool = true
      },
      // 关闭 修改订单 抽屉
      atrialstateClose(val){
         this.drawerBool = val
      },

      // 更多操作
      dropdownCommand(val){
         switch (val){
            case 'handleCancelCheckout':
               return this.handleInOut('cancelOut')
            case 'handlerecoverReserve':
               return this.handleInOut('recoverReserve')
            case 'handlecancelCheckIn':
               return this.handleInOut('cancelIn')
            case "handlecancelReserve":
               return this.handleInOut('cancelReserve')
            case "handleDeleteOrder":
               this.deleteOrder()
         }
      },

      // 显示 办理入住/退房 撤销入住/退房/预定 抽屉
      handleInOut(state){
         this.checkInBool = true
         this.checkInState = state
         this.checkinRoomDTOLists = []
         let inParam = false
         this.checkinRoomDTOList.forEach(item=>{
           // 办理入住
           if(state === 'in') if(item.checkInState === 'NOT_CHECKIN') {
                 // 过滤掉今天之后日期的房间信息
                 if(!dateFactory.compareTime(item.startTime.substring(0,10),dateFactory.getDistanceToday(0,false))){
                    this.checkinRoomDTOLists.push(item)
                    // 未排房时，获取房型房号cascader
                    if(!item.hasOwnProperty('roomNo')){
                       item.rooms = [item.roomTypeId,item.roomId]
                       inParam = {
                          hotelId:this.hotelInfo.id,
                          orderSource:item.channel,
                          checkInType:item.checkInType
                       }
                    }
                 }
           }
           // 办理退房
           if(state === 'out') if(item.checkInState === 'CHECKIN') this.checkinRoomDTOLists.push(item)
           // 撤销入住
           if(state === 'cancelIn') if(item.checkInState === 'CHECKIN') this.checkinRoomDTOLists.push(item)
           // 撤销退房
           if(state === 'cancelOut') if(item.checkInState === 'CHECKOUT') this.checkinRoomDTOLists.push(item)
            // 取消预定
           if(state === 'cancelReserve') if(item.checkInState === 'NOT_CHECKIN') {
               item.liquidatedDamages = 0 // 设置违约金初始值
               this.checkinRoomDTOLists.push(item)
            }
            // 恢复预定
           if(state === 'recoverReserve') if(item.checkInState === 'CANCEL') this.checkinRoomDTOLists.push(item)
         })
         if(state === 'cancelReserve') this.liquidatedDamagesChange()

         if(inParam) this.getCascader(inParam)
      },

      // 删除取消单
      deleteOrder() {
         this.$confirm('是否确认删除该取消单？', '删除', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
         }).then(() => {
            this.$axios.post('pms/bookInfo/removeBookOrder', {id: this.bookIds}).then(res => {
               if (res.success) {
                  this.closeDrawer()
                  this.$message.success('删除取消单成功');
               } else {
                  this.$message.error(res.message);
                  console.log(res);
               }
            })
         })
      },

      // 房型cascader展开时触发 处理cascader被占用不可选
      visibleChange(bool,row){
         if(bool){
            let param = {
               hotelId:this.hotelInfo.id,
               orderSource:row.channel,
               checkInType:row.checkInType,
               beginDate:row.startTime.substring(0,10),
               duration:Number(row.durationText.substring(0,row.durationText.indexOf('晚')))
            }
            if(row.checkInType === 'HOUR_ROOM') param.duration = Number(row.durationText.substring(0,row.durationText.indexOf('小')))
            this.getCascader(param,row.roomTypeId)
         }
      },

      // 关闭 办理入住 抽屉
      handleInclose(){
         this.isIndeterminate = true
         this.checkedCheckInOrder = []
         this.checkInBool = false
      },
      // 多选框 全选
      handleCheckAllChange(val) {
         this.checkedCheckInOrder = val ? this.checkinRoomDTOLists : [];
         this.isIndeterminate = false;
      },
      // 确认 办理(入住in/退房out) 撤销(入住cancelIn/退房cancelOut/预定cancelReserve)
      handleInFinish(){
         if(!this.checkedCheckInOrder.length) return this.$message.error('至少选择一条记录')
         let checkinRoomDTOList = []
         let cancelReserveKey = []
         let returnBool = false
         this.checkedCheckInOrder.forEach(item=>{
            if(this.checkInState === 'in'){
               // 未排房 进行排房入住
               if(item.hasOwnProperty('rooms') && item.rooms.length ){
                  checkinRoomDTOList.push({
                     id:item.id,
                     roomTypeName:this.anyMaps.get(item.rooms[0]),
                     roomId:item.rooms[1],
                     roomNo:this.anyMaps.get(item.rooms[1]),
                  })
                  if(!item.rooms[1]) return returnBool = true // 未排房
               }else{
                  checkinRoomDTOList.push({
                     id:item.id,
                     roomTypeName:item.roomTypeName,
                     roomId:item.roomId,
                     roomNo:item.roomNo,
                  })
               }

            }else if(this.checkInState === 'out'){
               checkinRoomDTOList.push({
                  roomTypeName:item.roomTypeName,
                  id:item.id,
                  // startTime:item.startTime, // 入住时间
               })
            } if( this.checkInState === 'cancelOut' || this.checkInState === 'cancelIn' || this.checkInState === 'recoverReserve'){
               checkinRoomDTOList.push({
                  roomTypeName:item.roomTypeName,
                  id:item.id,
               })
            }else if(this.checkInState === 'cancelReserve'){
               cancelReserveKey.push(item.roomTypeId + '_' + item.roomNo + '_' + item.startTime.substring(0,10))
               checkinRoomDTOList.push({
                  roomTypeName:item.roomTypeName,
                  id:item.id,
                  liquidatedDamages:item.liquidatedDamages
               })
            }
         })
         // 办理入住时 验证是否排房
         if(returnBool) return this.$message.error('请选择房间后再入住')
         let paran = {
            hotelId:this.hotelInfo.id,
            bookId:this.bookIds,
            remark:this.CheckInOrderRemark,
            checkinRoomDTOList:checkinRoomDTOList
         }
         handlecheckInOut(paran,this.checkInState,this.abnormalOrders).then(res=>{
            if(res){
               this.handleInclose()
               this.getDetail()
               this.$emit('getRoomCalendars') // 刷新 房态日历视图  // cancelReserveKey
               this.$message.success(`办理${
                     this.checkInState === 'in' ? '办理入住' : this.checkInState === 'cancelIn' ? '撤销入住' : this.checkInState === 'out' ? '办理退房' : this.checkInState === 'cancelOut ' ? '撤销退房' : this.checkInState === 'cancelReserve' ? '取消预定' : ''
               }成功`)
            }
         })
      },

      // 编辑
      // handleInEdit (){ },

      getCascader(param,roomTypeIdArr){
         getRoomPriceList(param).then(res=>{
            if(res.success){
               let resData = res.records
               this.roomOptions = []
               resData.forEach((item,index)=>{
                  if(!roomTypeIdArr){
                     this.anyMaps.set(item.id,item.roomTypeName)
                     item.rooms.forEach(childrenItem=>{ this.anyMaps.set(childrenItem.id,childrenItem.roomNo) })
                  }
                  this.roomOptions.push({
                     value: item.id,
                     label: item.roomTypeName,
                     children: [{value:'',label:'未排房',roomPrice:item.roomTypePrice}],
                     disabled:roomTypeIdArr === item.id ? false : true
                  })
                  item.rooms.forEach(childrenItem=>{
                     this.roomOptions[index].children.push({
                        value: childrenItem.id,
                        label: childrenItem.roomNo,
                        roomPrice: childrenItem.roomPrice,
                        disabled:childrenItem.isUse ? true : false,      // false可选 true为禁选
                     })
                  })
               })
            }
         })
      },
   },
   filters:{
      // 订单状态
      filterCheckInState(val){
         switch (val){
            case 'NOT_CHECKIN' :
               return '已预订'
            case 'CHECKIN' :
               return '已入住'
            case 'CHECKOUT' :
               return '已退房'
            case 'CANCEL' :
               return '已取消'
            default :
               return '-'
         }
      },
      filterOrderState(val,that){
         switch (val){
            case 'Confirmed' :
               that.ordertagStateType = 'warning'
               return '已确认'
            case 'InProgress' :
               that.ordertagStateType = ''
               return '进行中'
            case 'Finished' :
               that.ordertagStateType = 'info'
               return '已完成'
            case 'Cancel' :
               that.ordertagStateType = 'info'
               return '已取消'
            default :
               return '-'
         }
      },

      // 渠道
      filterChannel(val,that){
         if(val && that.dictData.hasOwnProperty('order-source-list')){
            if (that.dictData['order-source-list'].length){
               that.dictData['order-source-list'].forEach(item=>{
                  if(item.value === val)  val = item.label
               })
               return val
            }
         }
      },
      // 收款类型
      filterIncome(val,that){
         let arr = [...that.dictData['back-money-type-list'] , ...that.dictData['income-list'] ];
         if (val && arr.length){
            arr.forEach(item=>{
               if(item.value === val) val = item.label
            })
            return val
         }
      },
      // 支付方式
      filterPaymentMethod(val,that){
         if (val && that.dictData['pms-pay-method-list'].length){
            that.dictData['pms-pay-method-list'].forEach(item=>{
               if(item.value === val)  val = item.label
            })
            return val
         }
      },
      // 日志操作类型
      filterLogType(val){
         switch (val){
            case 'ORDER_ADD' :
               return '创建订单'
            case 'ORDER_UPDATE' :
               return '修改订单'
            case 'ORDER_CANCEL' :
               return '取消预订'
            case 'ORDER_RECOVER' :
               return '恢复预订'
            case 'CONSUMPTION_ADD' :
               return '新增消费'
            case 'CONSUMPTION_DELETE' :
               return '删除消费'
            case 'COLLECTION_ADD' :
               return '新增收款'
            case 'COLLECTION_DELETE' :
               return '删除收款'
            case 'REFUND_ADD' :
               return '新增退款'
            case 'REFUND_DELETE' :
               return '删除退款'
            case 'ORDER_REMINDER_ADD' :
               return '新增订单提醒'
            case 'ORDER_REMINDER_UPDATE' :
               return '修改订单提醒'
            case 'ORDER_REMINDER_DELETE' :
               return '删除订单提醒'
            case 'CHECKIN_MEMBER_ADD' :
               return '新增入住人'
            case 'CHECKIN_MEMBER_UPDATE' :
               return '修改入住人'
            case 'CHECKIN_MEMBER_DELETE' :
               return '删除入住人'
            case 'CHECK_IN' :
               return '办理入住'
            case 'CHECK_OUT' :
               return '办理退房'
            case 'CHECK_IN_CANCEL' :
               return '撤销入住'
            case 'CHECK_OUT_CANCEL' :
               return '撤销退房'
            default :
               return '-'
         }
      }
   }
}
</script>

<style scoped lang="scss">
$border-grey:#eff0f5;
.RMB{
   position: relative;
   left: 7px;
   color: #333;
}
.orderDetails{
   transition: right 0.6s ease;
   background-color: white;
   width: 36%;
   height: 100vh;
   position: fixed;
   top: 0;
   box-shadow: 0 0 18px -10px black;
   z-index: 2001;

   .border-b{border-bottom: 1px solid $border-grey;}
   .border-b-dashed{border-bottom: 1px dashed $border-grey;}

   // header
   .orderDetails-header{
      display: flex;
      justify-content: space-between;
      height: 65px;
      line-height: 65px;
      padding: 0 10px;
   }

   // main
   .orderDetails-main{
      height: calc( 100vh - 130px );
      // 用户信息
      .userInfo{
         display: flex;
         justify-content: space-between;
         .userInfo-item{
            font-size: 16px;
            font-weight: 600;
            span{ font-weight: 300; }
         }
         .channel-tag{
            height: 30px;
            line-height: 30px;
            padding: 0 5px;
            border: 1px solid #409eff;
            border-radius: 5px;
            color: #409eff;
            margin: auto 0;
            font-size: 14px;
            color: black;
            span{ display: inline-block;border: 4px solid #409eff;border-radius: 4px;margin-right: 2px; }
         }
      }
      // 金额
      .amountBox{
         list-style: none;
         display: flex;
         justify-content: space-between;
         padding: 20px 0;
         li{ font-size: 15px; font-weight: bold; }
         li:nth-child(1){ color: dodgerblue; }
         li:nth-child(2){ color: darkorange; }
         li:nth-child(3){ color: red; }
         &-title{
            color: black;
            margin-bottom: 5px;
            font-weight: 300;
         }
         span{ font-size: 18px; }
      }
      // 房间信息
      .roomsInfo{
         padding-bottom: 10px;
         &-item{
            font-size: 13px;
            border: 1px solid #d9ecff;
            padding: 20px;
            border-radius: 3px;
            display: flex;
            justify-content: space-between;
            list-style: none;
            margin-bottom: 10px;
            &-s{
               div:nth-child(1){ font-weight: bold; }
               div:nth-child(2){ color: gray;font-size: 12px; }
            }
            .m-auto{ margin: auto 0; }
         }
      }
      // 其他消费/收款金额/订单金额
      .consumeInfo{
         padding-bottom: 10px;
         &-collapse{
            position: relative;
            //border: none;
            border-top: none;
            margin-top: 10px;
            ::v-deep.el-collapse-item__header{ border-bottom: none!important; }
            ::v-deep.el-collapse-item__wrap{ border-bottom:none; }
            .collapse-title{
               text-indent: 25px;
               width: calc( 100% - 10px);
               display: flex;
               justify-content: space-between;
            }
           ::v-deep .el-icon-arrow-right{
               margin: 0;
               position: absolute;
              top: 18px;
              left: 6px;
            }
            // 订单提醒
            .warn-item{
               display: inline-block;
               width: 540px;
               height: 40px;
               line-height: 40px;
               background-color: $border-grey;
               font-size: 15px;
               color: #606266;
            }
         }
      }
   }
   // footer
   .orderDetails-footer{
      width: 100%;
      height: 65px;
      line-height: 65px;
      background-color: white;
      position: absolute;
      bottom: 0px;
      border-top: 1px solid $border-grey;
      padding: 0 10px;
      &-item{
         height: 35px;line-height: 35px; border: 1px solid #0c7ffd;margin: auto 0;padding: 0 8px;border-radius: 5px;color: #0c7ffd;
      }
   }

   // 操作日志
   .log_box{
      height: calc( 100vh - 130px );
      padding:20px 24px 0;
      .operateLog{
         .operateLog_tail{
            position: absolute;
            left: 3px;
            height: 100%;
            border-left: 2px solid #ccc;
            top: 2px;
         }
         position: relative;
         padding-bottom: 14px;
         &:last-child{
            margin-bottom: 50px;
         }
         &::before{
            content: "";
            width: 8px;
            height: 8px;
            background-color: #ccc;
            border-radius: 50%;
            position: absolute;
            top: 2px;
         }
         .log_content{
            padding-left: 24px;
            position: relative;
            top: -3px;
            .log_title{
               color: #999;
               font-size: 13px;
               white-space: pre-wrap;
               margin-bottom: 8px;
            }
            .log{
               color: #333;
               white-space: pre-wrap;
               font-size: 15px;
            }
         }
         &:last-child{
            .operateLog_tail{
               display: none;
            }
         }
      }
   }
}
.drawer-footer{
   width: 100%;
   height: 65px;
   line-height: 65px;
   background-color: white;
   position: absolute;
   bottom: 0px;
   border-top: 1px solid $border-grey;
   padding: 0 10px;
}
// 办理入住
.CheckInOrderBox{
   .el-checkbox{
      display: flex;
      justify-content: space-between;
      width: 100%;
      border: 1px solid gray;
      border-radius: 3px;
      margin-bottom: 10px;
      ::v-deep .el-checkbox__input{
         display: flex;
         .el-checkbox__inner{ margin: auto 0 auto 8px!important;}
      }
      ::v-deep .el-checkbox__label{
         display: inline-block;
         width: calc( 100% - 40px);
         .CheckInOrderBox-item{
            display: flex;

            .modInput{
               border: 1px solid #dedede;
               border-radius: 4px;
               padding: 5px;
               text-indent: 1em;
               outline: none;
               width: 60px;
               font-size: 9px;
            }
            .modInput:focus {
               border: 1px solid #409eff;
            }

         }
      }
   }
   .is-checked{ border: 1px solid #0c7ffd; }
}
.drawer {
   // header
   ::v-deep .el-drawer__open {
      .el-drawer__header {
         border-bottom: 1px solid #eff0f5;
         padding: 5px 20px;
         margin: 0;
         .el-page-header {
            border-bottom: 0px solid transparent
         }
      }
      .el-drawer {
         width: 36% !important;
      }
   }
   /deep/ .el-tabs__header{
      margin: 0 ;
   }
   // 金额
   .amountBox{
      margin: 24px 0;
      list-style: none;
      display: flex;
      justify-content: space-between;
      padding: 20px 0;
      li{ font-size: 15px; font-weight: bold; }
      li:nth-child(1){ color: dodgerblue; }
      li:nth-child(2){ color: darkorange; }
      li:nth-child(3){ color: red; }
      &-title{
         color: black;
         margin-bottom: 5px;
         font-weight: 300;
      }
      span{ font-size: 18px; }
   }
   .refund{
      display: flex;
      justify-content: space-evenly;
      margin-bottom: 24px;
      /deep/ .el-radio__input{
         display: none;
      }
      /deep/ .el-radio__input is-checked{
         display: none;
      }
   }
   .foot{
      width: 100%;
      position: absolute;
      bottom: 0px;
      padding: 12px 24px;
      text-align: end;
      border-top: 1px solid #E9EAED;
   }
}
</style>
